/* eslint-disable react/prop-types */
import { OverflowMenu as CarbonOverflowMenu } from '@carbon/react';
import { forwardRef } from 'react';

/** @typedef {import('react').ComponentProps<typeof CarbonOverflowMenu>} OverflowMenuProps */

/**
 * @param {OverflowMenuProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonOverflowMenu}
 */
const OverflowMenuForwarded = (props, ref) => (
  <CarbonOverflowMenu {...props} ref={ref} />
);

export const OverflowMenu = forwardRef(OverflowMenuForwarded);
