import { AiGenerate as CarbonAiGenerate } from '@carbon/icons-react';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getAiGenerateIcon = (size = 16) => {
  const AiGenerateIcon = (props) => <CarbonAiGenerate size={size} {...props} />;

  AiGenerateIcon.propTypes = iconPropTypes;
  AiGenerateIcon.defaultProps = iconDefaultProps;

  return AiGenerateIcon;
};

export const AiGenerate16 = getAiGenerateIcon();
export const AiGenerate20 = getAiGenerateIcon(20);
export const AiGenerate24 = getAiGenerateIcon(24);
export const AiGenerate32 = getAiGenerateIcon(32);
