import { ArrowUp as CarbonArrowUp } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getArrowUpIcon = (size = 16) => {
  const ArrowUpIcon = (props) => <CarbonArrowUp size={size} {...props} />;

  ArrowUpIcon.propTypes = iconPropTypes;
  ArrowUpIcon.defaultProps = iconDefaultProps;

  return ArrowUpIcon;
};

export const ArrowUp16 = getArrowUpIcon();
export const ArrowUp20 = getArrowUpIcon(20);
export const ArrowUp24 = getArrowUpIcon(24);
export const ArrowUp32 = getArrowUpIcon(32);
