import { Login as CarbonLogin } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getLoginIcon = (size = 16) => {
  const LoginIcon = (props) => <CarbonLogin size={size} {...props} />;

  LoginIcon.propTypes = iconPropTypes;
  LoginIcon.defaultProps = iconDefaultProps;

  return LoginIcon;
};

export const Login16 = getLoginIcon();
export const Login20 = getLoginIcon(20);
export const Login24 = getLoginIcon(24);
export const Login32 = getLoginIcon(32);
