import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { colors } from '@carbon/colors';
import { Routes } from './routes';
import { AuthorizedApolloProvider } from './graphql/AuthorizedApolloProvider';
import { Auth0ProviderWithHistory } from './auth/Auth0ProviderWithHistory';
import { ConfirmModalProvider } from './components/ConfirmModal';
import { ThemeProvider } from './helpers/themeContext';
import { GlobalStyle, GlobalGliaTheme } from './GlobalStyle';
import { NotificationProvider } from './context/notificationContext';
import { NotificationBar } from './components/Notifications/notificationBar';
import { CurrentUserProvider } from './context/currentUserContext';

const App = () => (
  <Router>
    <ThemeProvider>
      <Auth0ProviderWithHistory>
        <AuthorizedApolloProvider>
          <CurrentUserProvider>
            <NotificationProvider>
              <NotificationBar />
              <ConfirmModalProvider>
                <GlobalStyle />
                <GlobalGliaTheme colors={colors} />
                <Routes />
              </ConfirmModalProvider>
            </NotificationProvider>
          </CurrentUserProvider>
        </AuthorizedApolloProvider>
      </Auth0ProviderWithHistory>
    </ThemeProvider>
  </Router>
);

export { App };
