export const createGoToResponseUrl = (
  currentLocation,
  currentCustomerCode,
  responseCustomerCode,
  responseId
) => {
  const newUrl = new URL(currentLocation);
  newUrl.searchParams.delete('locale');
  newUrl.searchParams.delete('channel');
  newUrl.searchParams.set('responseId', responseId);

  const [urlPath, ...parts] = newUrl.href.toString().split('?');
  const urlParams = parts.join('?');
  const [urlStart] = urlPath.split(currentCustomerCode);

  return `${urlStart}${responseCustomerCode}?${urlParams}`;
};

export const getCurrentOriginAndCustomer = (url) => {
  const urlParts = url.split('/');
  const relevantParts = urlParts.slice(0, -1);
  return relevantParts.join('/');
};

export const getContentLinkedRedirectUrlFromOrigin = (
  originAndCustomer,
  responseId
) => `${originAndCustomer}?responseId=${responseId}`;

export const getDestinationButtonRedirectUrlFromOrigin = (
  originAndCustomer,
  searchString
) =>
  `${originAndCustomer}/conversation-elements-library/button?destination=${encodeURIComponent(
    searchString
  )}`;
