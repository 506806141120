import { ChevronLeft as CarbonChevronLeft } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getChevronLeftIcon = (size = 16) => {
  const ChevronLeftIcon = (props) => (
    <CarbonChevronLeft size={size} {...props} />
  );

  ChevronLeftIcon.propTypes = iconPropTypes;
  ChevronLeftIcon.defaultProps = iconDefaultProps;

  return ChevronLeftIcon;
};

export const ChevronLeft16 = getChevronLeftIcon();
export const ChevronLeft20 = getChevronLeftIcon(20);
export const ChevronLeft24 = getChevronLeftIcon(24);
export const ChevronLeft32 = getChevronLeftIcon(32);
