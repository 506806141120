import { TrashCan as CarbonTrashCan } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getTrashCanIcon = (size = 16) => {
  const TrashCanIcon = (props) => <CarbonTrashCan size={size} {...props} />;

  TrashCanIcon.propTypes = iconPropTypes;
  TrashCanIcon.defaultProps = iconDefaultProps;

  return TrashCanIcon;
};

export const TrashCan16 = getTrashCanIcon();
export const TrashCan20 = getTrashCanIcon(20);
export const TrashCan24 = getTrashCanIcon(24);
export const TrashCan32 = getTrashCanIcon(32);
