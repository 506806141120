import { Information as CarbonInformation } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getInformationIcon = (size = 16) => {
  const InformationIcon = (props) => (
    <CarbonInformation size={size} {...props} />
  );

  InformationIcon.propTypes = iconPropTypes;
  InformationIcon.defaultProps = iconDefaultProps;

  return InformationIcon;
};

export const Information16 = getInformationIcon();
export const Information20 = getInformationIcon(20);
export const Information24 = getInformationIcon(24);
export const Information32 = getInformationIcon(32);
