import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Content,
  SideNav,
  SideNavItems,
  SkipToContent,
  HeaderGlobalBar,
  HeaderContainer,
  HeaderMenuButton,
  HeaderNavigation,
  HeaderSideNavItems,
  HeaderGlobalAction,
} from '@cms/ui';
import { ArrowRight20 } from '@cms/icons';
import { GliaLogo } from '../Common/GliaLogo';

import {
  Header,
  PageHeader,
  TileContainer,
  ClickableTile,
  TileTitle,
  NavLink,
  LogoutIcon,
} from './Style';

const Home = () => {
  const { logout } = useAuth0();
  const { isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="container">
      {isAuthenticated ? (
        <HeaderContainer
          render={({ isSideNavExpanded, onClickSideNavExpand }) => (
            <>
              <Header aria-label="Glia">
                <SkipToContent />
                <HeaderMenuButton
                  aria-label="Open menu"
                  onClick={onClickSideNavExpand}
                  isActive={isSideNavExpanded}
                />
                <NavLink className="headerName" to="/">
                  <GliaLogo isWhite />
                </NavLink>
                <HeaderNavigation aria-label="Glia Virtual Assistant Portal">
                  <NavLink to="/cms">GVA Knowledge Bank</NavLink>
                  <NavLink
                    to={window.config.ANALYTICS_DASHBOARD_LINK}
                    target="_blank"
                  >
                    Analytics
                  </NavLink>
                </HeaderNavigation>
                <SideNav
                  aria-label="Side navigation"
                  expanded={isSideNavExpanded}
                  isPersistent={false}
                >
                  <SideNavItems>
                    <HeaderSideNavItems>
                      <NavLink to="/cms">GVA Knowledge Bank</NavLink>
                      <NavLink
                        to={window.config.ANALYTICS_DASHBOARD_LINK}
                        target="_blank"
                      >
                        Analytics
                      </NavLink>
                    </HeaderSideNavItems>
                  </SideNavItems>
                </SideNav>

                <HeaderGlobalBar>
                  <HeaderGlobalAction
                    className="logout-trigger"
                    aria-label="Log out"
                    onClick={() =>
                      logout({ returnTo: `${window.location.origin}/good-bye` })
                    }
                  >
                    <LogoutIcon />
                  </HeaderGlobalAction>
                </HeaderGlobalBar>
              </Header>
              <Content data-test="trackHomePage">
                <div className="bx--grid">
                  <div className="bx--row">
                    <section className="bx--col-lg-13">
                      <h1>Welcome to Glia Virtual Assistant Portal</h1>
                      <p>
                        Your one-stop shop for managing your Virtual Assistant
                      </p>
                      <TileContainer data-test="trackHomePageContentManagement">
                        <NavLink to="/cms">
                          <ClickableTile>
                            <TileTitle>GVA Knowledge Bank</TileTitle>
                            <ArrowRight20 />
                          </ClickableTile>
                        </NavLink>
                        <NavLink
                          to={window.config.ANALYTICS_DASHBOARD_LINK}
                          target="_blank"
                        >
                          <ClickableTile>
                            <TileTitle>Analytics</TileTitle>
                            <ArrowRight20 />
                          </ClickableTile>
                        </NavLink>
                      </TileContainer>
                    </section>
                  </div>
                </div>
              </Content>
            </>
          )}
        />
      ) : (
        !isLoading && (
          <Content>
            <div className="bx--grid">
              <div className="bx--row">
                <section className="bx--offset-lg-2 bx--col-lg-6">
                  <GliaLogo width={200} />
                  <PageHeader>Virtual Assistant Portal</PageHeader>
                  <p>Log in to manage your Virtual Assistant&apos;s content.</p>
                  <Button
                    className="login-button"
                    type="button"
                    renderIcon={LogoutIcon}
                    onClick={() => loginWithRedirect()}
                  >
                    Log in
                  </Button>
                </section>
              </div>
            </div>
          </Content>
        )
      )}
    </div>
  );
};

export { Home };
