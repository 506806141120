import { Badge as CarbonBadge } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getBadgeIcon = (size = 16) => {
  const BadgeIcon = (props) => <CarbonBadge size={size} {...props} />;

  BadgeIcon.propTypes = iconPropTypes;
  BadgeIcon.defaultProps = iconDefaultProps;

  return BadgeIcon;
};

export const Badge16 = getBadgeIcon();
export const Badge20 = getBadgeIcon(20);
export const Badge24 = getBadgeIcon(24);
export const Badge32 = getBadgeIcon(32);
