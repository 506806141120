import { WarningAlt as CarbonWarningAlt } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getWarningAltIcon = (size = 16) => {
  const WarningAltIcon = (props) => <CarbonWarningAlt size={size} {...props} />;

  WarningAltIcon.propTypes = iconPropTypes;
  WarningAltIcon.defaultProps = iconDefaultProps;

  return WarningAltIcon;
};

export const WarningAlt16 = getWarningAltIcon();
export const WarningAlt20 = getWarningAltIcon(20);
export const WarningAlt24 = getWarningAltIcon(24);
export const WarningAlt32 = getWarningAltIcon(32);
