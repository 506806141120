/* eslint-disable react/prop-types */
import { Search as CarbonSearch } from '@carbon/react';
import { forwardRef } from 'react';

/**
 *
 * @param {import('@carbon/react/lib/components/Search/Search').SearchProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonSearch}
 */
const SearchForwarded = (props, ref) => <CarbonSearch ref={ref} {...props} />;

export const Search = forwardRef(SearchForwarded);
