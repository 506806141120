import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = async (appState) => {
    const redirectPath = appState?.returnTo || window.location.pathname;
    navigate(redirectPath || '/');
  };

  return (
    <Auth0Provider
      domain={window.config.AUTH0_DOMAIN}
      clientId={window.config.AUTH0_CLIENT_ID}
      audience={window.config.AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { Auth0ProviderWithHistory };
