import { ArrowDown as CarbonArrowDown } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getArrowDownIcon = (size = 16) => {
  const ArrowDownIcon = (props) => <CarbonArrowDown size={size} {...props} />;

  ArrowDownIcon.propTypes = iconPropTypes;
  ArrowDownIcon.defaultProps = iconDefaultProps;

  return ArrowDownIcon;
};

export const ArrowDown16 = getArrowDownIcon();
export const ArrowDown20 = getArrowDownIcon(20);
export const ArrowDown24 = getArrowDownIcon(24);
export const ArrowDown32 = getArrowDownIcon(32);
