import { Document as CarbonDocument } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getDocumentIcon = (size = 16) => {
  const DocumentIcon = (props) => <CarbonDocument size={size} {...props} />;

  DocumentIcon.propTypes = iconPropTypes;
  DocumentIcon.defaultProps = iconDefaultProps;

  return DocumentIcon;
};

export const Document16 = getDocumentIcon();
export const Document20 = getDocumentIcon(20);
export const Document24 = getDocumentIcon(24);
export const Document32 = getDocumentIcon(32);
