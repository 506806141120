/* eslint-disable react/prop-types */
import { TableBody as CarbonTableBody } from '@carbon/react';
import { forwardRef } from 'react';

/**
 *
 * @param {import('@carbon/react/lib/components/DataTable/TableBody').TableBodyProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonTableBody}
 */
const TableBodyForwarded = ({ ...props }, ref) => (
  <CarbonTableBody ref={ref} {...props} />
);

export const TableBody = forwardRef(TableBodyForwarded);
