/* eslint-disable import/no-default-export */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const NotificationContext = React.createContext({
  notification: null,
  notificationText: null,
});

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationKind, setNotificationKind] = useState('error');

  const openNotification = ({ title, message, kind }) => {
    setNotification(true);
    setNotificationTitle(title);
    setNotificationMessage(message);
    setNotificationKind(kind);
  };

  const clearNotification = () => {
    setNotification(false);
    setNotificationTitle('');
    setNotificationMessage('');
    setNotificationKind('');
  };

  const openErrorNotification = ({ title = 'Error', message = '' }) => {
    openNotification({ title, message, kind: 'error' });
  };

  return (
    <NotificationContext.Provider
      value={{
        openErrorNotification,
        clearNotification,
        notification,
        notificationTitle,
        notificationMessage,
        notificationKind,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NotificationProvider };
export default NotificationContext;
