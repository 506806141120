/* eslint-disable react/prop-types */
import { TableHeader as CarbonTableHeader } from '@carbon/react';
import { forwardRef } from 'react';

/** @typedef {import('react').ComponentProps<typeof CarbonTableHeader>} TableHeaderProps */

/**
 * @param {TableHeaderProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonTableHeader}
 */
export const TableHeaderForwarded = ({ ...props }, ref) => (
  <CarbonTableHeader {...props} ref={ref} />
);

export const TableHeader = forwardRef(TableHeaderForwarded);
