import { ArrowLeft as CarbonArrowLeft } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getArrowLeftIcon = (size = 16) => {
  const ArrowLeftIcon = (props) => <CarbonArrowLeft size={size} {...props} />;

  ArrowLeftIcon.propTypes = iconPropTypes;
  ArrowLeftIcon.defaultProps = iconDefaultProps;

  return ArrowLeftIcon;
};

export const ArrowLeft16 = getArrowLeftIcon();
export const ArrowLeft20 = getArrowLeftIcon(20);
export const ArrowLeft24 = getArrowLeftIcon(24);
export const ArrowLeft32 = getArrowLeftIcon(32);
