import styled from 'styled-components';
import { Link as ReactLink } from 'react-router-dom';
import {
  Tile as CarbonClickableTile,
  Header as CarbonHeader,
  HeaderName as CarbonHeaderName,
} from '@cms/ui';
import { Login24 } from '@cms/icons';

const Header = styled(CarbonHeader)`
  background-color: ${({ theme }) => theme.layerSelectedInverse};
  a:hover {
    background-color: ${(p) => p.theme.inverseHoverUI};
  }
  .logout-trigger {
    background-color: transparent;
  }
`;

const HeaderName = styled(CarbonHeaderName)`
  a {
    color: red;
  }
`;

const PageHeader = styled.h1`
  margin-bottom: ${(p) => p.theme.spacing06};
`;

const TileContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: ${(p) => p.theme.spacing07};
`;

const ClickableTile = styled(CarbonClickableTile)`
  width: 27rem;
  height: 14rem;
  margin-bottom: ${(p) => p.theme.spacing05};
  margin-right: ${(p) => p.theme.spacing05};
  display: flex;
  border-bottom: ${(p) => p.theme.spacing01} solid
    ${(p) => p.theme.interactive01};

  svg {
    align-self: flex-end;
    margin-left: auto;
    fill: ${(p) => p.theme.interactive01};
  }

  &:hover {
    h4 {
      color: ${(p) => p.theme.interactive01};
    }
    svg {
      fill: ${(p) => p.theme.interactive01};
    }
  }
`;

const TileTitle = styled.h4`
  color: ${(p) => p.theme.ui05};
`;

const NavLink = styled(ReactLink)`
  text-decoration: none;
  padding: ${(p) => p.theme.spacing05};
  color: ${(p) => p.theme.ui01};

  &.headerName {
    padding-top: 0.8rem;
    padding-bottom: 0;
  }
`;

const LogoutIcon = styled(Login24)`
  fill: white !important;
`;

const ContentHeader = styled.section`
  margin-bottom: ${(p) => p.theme.spacing06};
`;

export {
  ClickableTile,
  TileContainer,
  TileTitle,
  HeaderName,
  PageHeader,
  Header,
  NavLink,
  LogoutIcon,
  ContentHeader,
};
