import { UserAvatar as CarbonUserAvatar } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getUserAvatarIcon = (size = 16) => {
  const UserAvatarIcon = (props) => <CarbonUserAvatar size={size} {...props} />;

  UserAvatarIcon.propTypes = iconPropTypes;
  UserAvatarIcon.defaultProps = iconDefaultProps;

  return UserAvatarIcon;
};

export const UserAvatar16 = getUserAvatarIcon();
export const UserAvatar20 = getUserAvatarIcon(20);
export const UserAvatar24 = getUserAvatarIcon(24);
export const UserAvatar32 = getUserAvatarIcon(32);
