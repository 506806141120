/* eslint-disable react/prop-types */
import { TableHead as CarbonTableHead } from '@carbon/react';
import { forwardRef } from 'react';

/**
 *
 * @param {import('@carbon/react/lib/components/DataTable/TableHead').TableHeadProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonTableHead}
 */
const TableHeadForwarded = ({ ...props }, ref) => (
  <CarbonTableHead ref={ref} {...props} />
);

export const TableHead = forwardRef(TableHeadForwarded);
