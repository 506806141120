import { CloudUpload as CarbonCloudUpload } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getCloudUploadIcon = (size = 16) => {
  const CloudUploadIcon = (props) => (
    <CarbonCloudUpload size={size} {...props} />
  );

  CloudUploadIcon.propTypes = iconPropTypes;
  CloudUploadIcon.defaultProps = iconDefaultProps;

  return CloudUploadIcon;
};

export const CloudUpload16 = getCloudUploadIcon();
export const CloudUpload20 = getCloudUploadIcon(20);
export const CloudUpload24 = getCloudUploadIcon(24);
export const CloudUpload32 = getCloudUploadIcon(32);
