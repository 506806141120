import { RequestQuote as CarbonRequestQuote } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getRequestQuoteIcon = (size = 16) => {
  const RequestQuoteIcon = (props) => (
    <CarbonRequestQuote size={size} {...props} />
  );

  RequestQuoteIcon.propTypes = iconPropTypes;
  RequestQuoteIcon.defaultProps = iconDefaultProps;

  return RequestQuoteIcon;
};

export const RequestQuote16 = getRequestQuoteIcon();
export const RequestQuote20 = getRequestQuoteIcon(20);
export const RequestQuote24 = getRequestQuoteIcon(24);
export const RequestQuote32 = getRequestQuoteIcon(32);
