import { ChevronRight as CarbonChevronRight } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getChevronRightIcon = (size = 16) => {
  const ChevronRightIcon = (props) => (
    <CarbonChevronRight size={size} {...props} />
  );

  ChevronRightIcon.propTypes = iconPropTypes;
  ChevronRightIcon.defaultProps = iconDefaultProps;

  return ChevronRightIcon;
};

export const ChevronRight16 = getChevronRightIcon();
export const ChevronRight20 = getChevronRightIcon(20);
export const ChevronRight24 = getChevronRightIcon(24);
export const ChevronRight32 = getChevronRightIcon(32);
