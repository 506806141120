import React from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import { ToastNotification, ActionableNotification } from '@cms/ui';
import { NOTIFICATION_TYPE } from '../../constants';

import { NotificationsRoot } from './Styles';

const Notifications = ({
  isExporting,
  notificationType,
  openExportTriggerResults,
  exportResultSuccessTime,
  invalidTemplate,
}) => {
  const seeErrorsInExportTriggerResults = (args) => {
    openExportTriggerResults(args);
  };
  const handleTemplateErrorClose = () => {
    const apolloErrorsElement = document.getElementById('apolloErrors');
    if (apolloErrorsElement) {
      render(<></>, apolloErrorsElement);
    }
  };
  return (
    <NotificationsRoot>
      {notificationType === NOTIFICATION_TYPE.LOADING && (
        <ToastNotification
          lowContrast
          kind="info"
          title={`Content ${isExporting ? 'export' : 'publish'} has started.`}
          aria-label="Dismiss"
          caption={Intl.DateTimeFormat(navigator.language, {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }).format(new Date())}
          subtitle={
            <span>
              {`You will get a notification here once all the content has been ${
                isExporting ? 'exported' : 'published'
              }`}
              <br />
              <br />
              {`Content ${
                isExporting ? 'export' : 'publish'
              } typically takes around`}{' '}
              <strong>{isExporting ? '1 minute' : '5 seconds'}</strong>.
            </span>
          }
          timeout={0}
        />
      )}
      {notificationType === NOTIFICATION_TYPE.SUCCESS && (
        <ToastNotification
          data-test="notificationSuccess"
          lowContrast
          kind="success"
          title={`Content ${
            isExporting ? 'export' : 'publish'
          } was successful!`}
          caption={Intl.DateTimeFormat(navigator.language, {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }).format(new Date())}
          aria-label="Dismiss"
          subtitle={
            <span>
              {`Content ${isExporting ? 'export' : 'publish'} took `}
              <strong>{exportResultSuccessTime} seconds.</strong>
              <br />
              <br />
              {`Your content is now in ${
                isExporting ? 'Test' : 'Live'
              } environment`}
            </span>
          }
          timeout={0}
        />
      )}
      {notificationType === NOTIFICATION_TYPE.ERROR && (
        <ActionableNotification
          data-test="notificationError"
          lowContrast
          kind="error"
          title={`Content ${
            isExporting ? 'export' : 'publish'
          } was unsuccessful.`}
          caption={Intl.DateTimeFormat(navigator.language, {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }).format(new Date())}
          aria-label="Dismiss"
          subtitle={
            <span>
              Find out what prevented it from completing successfully.
              <br />
              <br />
            </span>
          }
          actionButtonLabel="See results"
          onActionButtonClick={seeErrorsInExportTriggerResults}
          timeout={0}
        />
      )}
      {notificationType === NOTIFICATION_TYPE.UNAUTHORIZED && (
        <ToastNotification
          data-test="notificationUnauthorized"
          lowContrast
          kind="warning-alt"
          title="It seems like you are not authorized to export your content."
          aria-label="Dismiss"
          caption={Intl.DateTimeFormat(navigator.language, {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }).format(new Date())}
          subtitle={
            <span>
              Your account doesn&apos;t have the right access to export content.
              <br />
              Please contact your admin for further details.
            </span>
          }
          timeout={0}
        />
      )}
      {notificationType === NOTIFICATION_TYPE.TEMPLATE_ERROR && (
        <ToastNotification
          lowContrast
          kind="error"
          title="Unable to save your changes"
          caption={Intl.DateTimeFormat(navigator.language, {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }).format(new Date())}
          subtitle={
            <span>
              Your updates were not saved because they are trying to access a
              variable
              <strong>
                &nbsp;
                {invalidTemplate}
                &nbsp;
              </strong>
              that doesn&apos;t exist in the CMS. Please verify that all
              variables are spelled correctly and try saving again. If the issue
              keeps happening please contact your Customer Success Manager.
              <br />
              <br />
              [TemplateError]
            </span>
          }
          aria-label="Dismiss"
          timeout={0}
          onClose={handleTemplateErrorClose}
        />
      )}
    </NotificationsRoot>
  );
};

Notifications.propTypes = {
  notificationType: PropTypes.string,
  openExportTriggerResults: PropTypes.func,
  exportResultSuccessTime: PropTypes.node,
  isExporting: PropTypes.bool,
  invalidTemplate: PropTypes.string,
};

Notifications.defaultProps = {
  notificationType: '',
  openExportTriggerResults: () => {},
  exportResultSuccessTime: undefined,
  isExporting: true,
  invalidTemplate: '',
};

export { Notifications };
