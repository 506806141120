import { Logout as CarbonLogout } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getLogoutIcon = (size = 16) => {
  const LogoutIcon = (props) => <CarbonLogout size={size} {...props} />;

  LogoutIcon.propTypes = iconPropTypes;
  LogoutIcon.defaultProps = iconDefaultProps;

  return LogoutIcon;
};

export const Logout16 = getLogoutIcon();
export const Logout20 = getLogoutIcon(20);
export const Logout24 = getLogoutIcon(24);
export const Logout32 = getLogoutIcon(32);
