import { ExpandCategories as CarbonExpandCategories } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getExpandCategoriesIcon = (size = 16) => {
  const ExpandCategoriesIcon = (props) => (
    <CarbonExpandCategories size={size} {...props} />
  );

  ExpandCategoriesIcon.propTypes = iconPropTypes;
  ExpandCategoriesIcon.defaultProps = iconDefaultProps;

  return ExpandCategoriesIcon;
};

export const ExpandCategories16 = getExpandCategoriesIcon();
