/* eslint-disable react/prop-types */
import { Tag as CarbonTag } from '@carbon/react';
import { forwardRef } from 'react';

/** @typedef {import('react').ComponentProps<typeof CarbonTag>} TagProps */

/**
 * @param {TagProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonTag}
 */
export const TagForwarded = ({ ...props }, ref) => (
  <CarbonTag {...props} ref={ref} />
);

export const Tag = forwardRef(TagForwarded);
