import PropTypes from 'prop-types';
import { PlayFilled as CarbonPlayFilled } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getPlayFilledIcon = (size = 16) => {
  const PlayFilledIcon = (props) => <CarbonPlayFilled size={size} {...props} />;

  PlayFilledIcon.propTypes = { ...iconPropTypes, color: PropTypes.string };
  PlayFilledIcon.defaultProps = iconDefaultProps;

  return PlayFilledIcon;
};

export const PlayFilled16 = getPlayFilledIcon();
export const PlayFilled20 = getPlayFilledIcon(20);
export const PlayFilled24 = getPlayFilledIcon(24);
export const PlayFilled32 = getPlayFilledIcon(32);
