import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background-color: ${(p) => p.theme.ui01_5};
    color: ${(p) => p.theme.text01};
    ${(p) => p.theme.bodyShort01}

    .MuiAutocomplete-popper {
      z-index: 9000;
    }

    .MuiAutocomplete-paper {
      margin: 0;
    }
    .MuiPaper-rounded {
      border-radius: 0;
    }
    .MuiAutocomplete-listbox {
      padding: 0;
      background-color: #F4F4F4;
    }
    .MuiAutocomplete-option {
      background-color: white;
      margin: 0 0 4px 0;
    }
    .MuiAutocomplete-option:last-child {
      margin: 0;
    }
    .MuiAutocomplete-noOptions {
      display: none;
    }
  }

  #root {
    max-width: 100vw;
    max-height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .bx--overflow-menu-options[data-floating-menu-direction='bottom']::after {
    width: 2rem;
  }
  .bx--overflow-menu-options__option--danger {
    border-top: none;
  }
  .bx--overflow-menu-options__option {
    height: 2rem;
  }
  .bx--overflow-menu-options__btn {
    padding: 0 0.5rem;
  }
  .bx--overflow-menu-options {
    z-index: 9000;
    inline-size: 11rem;
  }
  .bx--snippet--inline {
    background-color: #f4f4f4 !important;
    color: #161616 !important;
  }
  .login-button {
    margin-top: 1rem;
  }

  audio::-webkit-media-controls-panel {
    background-color: white;
  }
`;

const GlobalGliaTheme = createGlobalStyle`
    a,
    .bx--link {
      color: ${(p) => p.colors.purple[60]};
    }
    .bx--link:hover,
    .bx--link:visited:hover,
    .bx--btn--ghost:hover,
    .bx--btn--ghost:active {
      color: ${(p) => p.colors.purple[70]};
    }
    
    .bx--btn--ghost {
      color: ${(p) => p.colors.purple[70]};
    }
    .react-flow__controls {
      box-shadow: none !important;
    }
    
    .bx--btn--ghost:focus,
    .bx--btn--primary:focus,
    .bx--modal-close:focus {
      box-shadow: inset 0 0 0 1px ${(p) =>
        p.colors.purple[60]}, inset 0 0 0 2px #fff;
      border-color: ${(p) => p.colors.purple[60]};
    }

    .bx--btn--primary,
    .bx--btn--primary:hover,
    .bx--btn--tertiary:hover {
      background-color: #7C19DD;
    }

    .bx--tooltip__trigger:not(.bx--btn--icon-only):focus {
      outline-color: ${(p) => p.colors.purple[60]}
      fill: #7C19DD;
    }
    a.bx--header__menu-item[aria-current='page']::after,
    .bx--header__menu-item--current::after {
      border-bottom-color: ${(p) => p.colors.purple[50]}
    }

    .bx--select-input:focus
    .bx--search-input:focus,
    .bx--checkbox:focus + .bx--checkbox-label::before, 
    .bx--checkbox-label__focus::before, 
    .bx--checkbox:checked:focus + .bx--checkbox-label::before, 
    .bx--checkbox-label[data-contained-checkbox-state='true'].bx--checkbox-label__focus::before, 
    .bx--checkbox:indeterminate:focus + .bx--checkbox-label::before, 
    .bx--checkbox-label[data-contained-checkbox-state='mixed'].bx--checkbox-label__focus::before {
      outline-color: ${(p) => p.colors.purple[60]}
    }
    .bx--loading__stroke {
      stroke: ${(p) => p.colors.purple[60]};
    }

    .bx--tile .bx--overflow-menu__wrapper {
      .bx--btn--primary {
        background-color: transparent;
      }

      .bx--btn--primary:hover {
        background-color: rgba(230, 230, 230);
      }
    }
    .qr {
    border: none;
    color: ${(p) => p.colors.purple[70]};
    display: inline;
    white-space: wrap;
    font-weight: 500;
    cursor: pointer;
  }
  .qr:hover {
    text-decoration: underline;
  }
`;

export { GlobalStyle, GlobalGliaTheme };
