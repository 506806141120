import { MachineLearningModel as CarbonMachineLearningModel } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getMachineLearningModelIcon = (size = 16) => {
  const MachineLearningModelIcon = (props) => (
    <CarbonMachineLearningModel size={size} {...props} />
  );

  MachineLearningModelIcon.propTypes = iconPropTypes;
  MachineLearningModelIcon.defaultProps = iconDefaultProps;

  return MachineLearningModelIcon;
};

export const MachineLearningModel16 = getMachineLearningModelIcon();
export const MachineLearningModel20 = getMachineLearningModelIcon(20);
export const MachineLearningModel24 = getMachineLearningModelIcon(24);
export const MachineLearningModel32 = getMachineLearningModelIcon(32);
