import { RowExpand as CarbonRowExpand } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getRowExpandIcon = (size = 16) => {
  const RowExpandIcon = (props) => <CarbonRowExpand size={size} {...props} />;

  RowExpandIcon.propTypes = iconPropTypes;
  RowExpandIcon.defaultProps = iconDefaultProps;

  return RowExpandIcon;
};

export const RowExpand16 = getRowExpandIcon();
export const RowExpand20 = getRowExpandIcon(20);
export const RowExpand24 = getRowExpandIcon(24);
export const RowExpand32 = getRowExpandIcon(32);
