import { gql, useQuery } from '@apollo/client';

export const GET_USERS_DATA_QUERY = gql`
  query GetUsersData($userEmail: String!) {
    users(where: { email: { equals: $userEmail } }) {
      id
      customers {
        name
        code
      }
    }
  }
`;

export const useUsersData = (userEmail) =>
  useQuery(GET_USERS_DATA_QUERY, {
    variables: {
      userEmail,
    },
    skip: !userEmail,
  });
