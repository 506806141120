import { Copy as CarbonCopyIcon } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getCopyIcon = (size = 16) => {
  const CopyIcon = (props) => <CarbonCopyIcon size={size} {...props} />;

  CopyIcon.propTypes = iconPropTypes;
  CopyIcon.defaultProps = iconDefaultProps;

  return CopyIcon;
};

export const Copy16 = getCopyIcon();
export const Copy20 = getCopyIcon(20);
export const Copy24 = getCopyIcon(24);
export const Copy32 = getCopyIcon(32);
