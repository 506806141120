import { ErrorFilled as CarbonErrorFilled } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getErrorFilledIcon = (size = 16) => {
  const ErrorFilledIcon = (props) => (
    <CarbonErrorFilled size={size} {...props} />
  );

  ErrorFilledIcon.propTypes = iconPropTypes;
  ErrorFilledIcon.defaultProps = iconDefaultProps;

  return ErrorFilledIcon;
};

export const ErrorFilled16 = getErrorFilledIcon();
export const ErrorFilled20 = getErrorFilledIcon(20);
export const ErrorFilled24 = getErrorFilledIcon(24);
export const ErrorFilled32 = getErrorFilledIcon(32);
