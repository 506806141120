import { ArrowRight as CarbonArrowRight } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getArrowRightIcon = (size = 16) => {
  const ArrowRightIcon = (props) => <CarbonArrowRight size={size} {...props} />;

  ArrowRightIcon.propTypes = iconPropTypes;
  ArrowRightIcon.defaultProps = iconDefaultProps;

  return ArrowRightIcon;
};

export const ArrowRight16 = getArrowRightIcon();
export const ArrowRight20 = getArrowRightIcon(20);
export const ArrowRight24 = getArrowRightIcon(24);
export const ArrowRight32 = getArrowRightIcon(32);
