import { Filter as CarbonFilter } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getFilterIcon = (size = 16) => {
  const FilterIcon = (props) => <CarbonFilter size={size} {...props} />;

  FilterIcon.propTypes = iconPropTypes;
  FilterIcon.defaultProps = iconDefaultProps;

  return FilterIcon;
};

export const Filter16 = getFilterIcon();
export const Filter20 = getFilterIcon(20);
export const Filter24 = getFilterIcon(24);
export const Filter32 = getFilterIcon(32);
