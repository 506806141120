import React from 'react';
import { Route, Routes as RoutesContainer, Navigate } from 'react-router-dom';
import { ProtectedRoute } from './auth/ProtectedRoute';

import { Home } from './pages/Home';
import { withAccessControl } from './hoc';
import { NotFound } from './pages/NotFound';

const GoodBye = React.lazy(() =>
  import('./pages/GoodBye').then((module) => ({ default: module.GoodBye }))
);
const CmsHome = React.lazy(() =>
  import('./pages/cms/Home').then((module) => ({ default: module.Home }))
);
const ClientConfig = React.lazy(() =>
  import('./pages/cms/ClientConfig/components').then((module) => ({
    default: module.ClientConfigPage,
  }))
);
const Landing = React.lazy(() =>
  import('./pages/cms/Landing').then((module) => ({ default: module.Landing }))
);
const L1Selection = React.lazy(() =>
  import('./pages/cms/L1Selection').then((module) => ({
    default: module.L1Selection,
  }))
);
const Tag = React.lazy(() =>
  import('./pages/cms/L1Selection/Tag').then((module) => ({
    default: module.Tag,
  }))
);
const Assignee = React.lazy(() =>
  import('./pages/cms/L1Selection/Assignee').then((module) => ({
    default: module.Assignee,
  }))
);
const Status = React.lazy(() =>
  import('./pages/cms/L1Selection/Status').then((module) => ({
    default: module.Status,
  }))
);

const PUGG = React.lazy(() =>
  import('./pages/product-user-goal-generator').then((module) => ({
    default: module.Home,
  }))
);

const CELL = React.lazy(() =>
  import('./pages/conversation-elements-library').then((module) => ({
    default: module.CELL,
  }))
);

const Routes = () => (
  <React.Suspense fallback={<></>}>
    <RoutesContainer>
      <Route path="/" element={<Home />} />
      <Route path="/good-bye" element={<GoodBye />} />
      <Route path="/cms" element={<ProtectedRoute component={CmsHome} />} />
      <Route
        path="/cms/:client"
        element={<ProtectedRoute component={withAccessControl(ClientConfig)} />}
      />
      <Route
        path="/cms/:client/content-by"
        element={<ProtectedRoute component={withAccessControl(Landing)} />}
      />
      <Route
        path="/cms/:client/content-by/tag"
        element={<ProtectedRoute component={withAccessControl(Tag)} />}
      />
      <Route
        path="/cms/:client/content-by/assignee"
        element={<ProtectedRoute component={withAccessControl(Assignee)} />}
      />
      <Route
        path="/cms/:client/content-by/status"
        element={<ProtectedRoute component={withAccessControl(Status)} />}
      />
      <Route
        path="/cms/:client/user-goal-generator"
        element={<ProtectedRoute component={withAccessControl(PUGG)} />}
      />
      <Route
        path="/cms/:client/conversation-elements-library/:library?"
        element={<ProtectedRoute component={withAccessControl(CELL)} />}
      />
      <Route
        path="/cms/:client/content-by/:levelOne"
        element={<ProtectedRoute component={withAccessControl(L1Selection)} />}
      />
      <Route path="/not-found" element={<NotFound />} />

      {/* Redirect all unknown routes to not-found route */}
      <Route path="*" element={<Navigate to="/not-found" />} />
    </RoutesContainer>
  </React.Suspense>
);

export { Routes };
