import { Edit as CarbonEdit } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getEditIcon = (size = 16) => {
  const EditIcon = (props) => <CarbonEdit size={size} {...props} />;

  EditIcon.propTypes = iconPropTypes;
  EditIcon.defaultProps = iconDefaultProps;

  return EditIcon;
};

export const Edit16 = getEditIcon();
export const Edit20 = getEditIcon(20);
export const Edit24 = getEditIcon(24);
export const Edit32 = getEditIcon(32);
