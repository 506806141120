import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { themes } from '@carbon/themes';
import { colors } from '@carbon/colors';
import { ClassPrefix } from '@carbon/react';

const LS_KEY = 'finn.portal.theme';
const LIGHT = 'g10';
const DARK = 'g90';
const THEME_NAMES = [LIGHT, DARK];
const GLIA_PURPLE = colors.purple[60];

/** Company Colors */
const FINN_BACKGROUND = {
  [LIGHT]: 'rgb(57, 101, 98)',
  [DARK]: 'rgb(0,72,79)',
};

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [activeTheme, setActiveTheme] = useState(
    THEME_NAMES.includes(localStorage.getItem(LS_KEY))
      ? localStorage.getItem(LS_KEY)
      : LIGHT
  );

  // // Useful for building out themes; Displays the various values in the theme grouped by type
  // useEffect(() => {
  //   const themeValues = themes[activeTheme];
  //   const colors = [];
  //   const spacing = [];
  //   const typography = [];
  //   Object.keys(themeValues).map((k) => {
  //     if (typeof themeValues[k] === 'string'
  //       && (themeValues[k].indexOf('#') === 0 || themeValues[k].indexOf('rgb') === 0))
  //     {
  //       colors.push({ key: k, value: themeValues[k] });
  //     } else if (typeof themeValues[k] === 'object') {
  //       typography.push({ key: k, value: themeValues[k] });
  //     } else {
  //       spacing.push({ key: k, value: themeValues[k] });
  //     }
  //   });
  //   console.log('Colors', colors);
  //   console.log('Typography', typography);
  //   console.log('Spacing', spacing);
  // }, [activeTheme]);

  const handleSetTheme = (newThemeName) => {
    if (!THEME_NAMES.includes(newThemeName)) {
      return;
    }

    localStorage.setItem(LS_KEY, newThemeName);
    setActiveTheme(newThemeName);
  };

  const themeValues = {
    ...themes[activeTheme],
    // Augment theme with company colors
    finnBackground: FINN_BACKGROUND[activeTheme],
    customerStatusColors: {
      purple: '#e8daff',
      red: '#ffd7d9',
      magenta: '#ffd6e8',
      teal: '#9ef0f0',
      green: '#a7f0ba',
    },
    customerStatusTextColors: {
      purple: '#491d8b',
      red: '#750e13',
      magenta: '#740937',
      teal: '#004144',
      green: '#044317',
      delete: '#da1e28',
    },
    ui01_5: '#fafafa',

    interactive01: GLIA_PURPLE,
    interactive03: GLIA_PURPLE,
    interactive04: GLIA_PURPLE,
    link01: GLIA_PURPLE,
    focus: GLIA_PURPLE,
    brand01: GLIA_PURPLE,
    brand03: GLIA_PURPLE,

    link02: GLIA_PURPLE,
    support04: GLIA_PURPLE,
    hoverPrimaryText: GLIA_PURPLE,

    inverseSupport04: GLIA_PURPLE,
  };

  const exposedValues = {
    activeTheme,
    theme: themeValues,
    setTheme: handleSetTheme,
  };

  /**
   * Use both ThemeContext and StyledThemeProvider.
   * ThemeContext -> exposes ability for us to switch themes and use themes outside of
   *                 styled components
   * StyledThemeProvider -> makes using theme values within styled components easier
   */
  return (
    <ThemeContext.Provider value={exposedValues}>
      <ClassPrefix prefix="bx">
        <StyledThemeProvider theme={themeValues}>
          {children}
        </StyledThemeProvider>
      </ClassPrefix>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const useTheme = () => {
  const { activeTheme, theme, setTheme } = useContext(ThemeContext);

  return {
    activeTheme,
    theme,
    setTheme,
  };
};

export { ThemeProvider, useTheme, LIGHT, DARK };
