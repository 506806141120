/* eslint-disable react/prop-types */
import { TableExpandRow as CarbonTableExpandRow } from '@carbon/react';
import { forwardRef } from 'react';

/**
 *
 * @param {import('@carbon/react/lib/components/DataTable/TableExpandRow').TableExpandRow} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonTableExpandRow}
 */
const TableExpandRowForwarded = ({ ...props }, ref) => (
  <CarbonTableExpandRow ref={ref} {...props} />
);

export const TableExpandRow = forwardRef(TableExpandRowForwarded);
