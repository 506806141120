import { EditOff as CarbonEditOff } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getEditOffIcon = (size = 16) => {
  const EditOffIcon = (props) => <CarbonEditOff size={size} {...props} />;

  EditOffIcon.propTypes = iconPropTypes;
  EditOffIcon.defaultProps = iconDefaultProps;

  return EditOffIcon;
};

export const EditOff16 = getEditOffIcon();
export const EditOff20 = getEditOffIcon(20);
export const EditOff24 = getEditOffIcon(24);
export const EditOff32 = getEditOffIcon(32);
