/* eslint-disable react/prop-types */
import { TextArea as CarbonTextArea } from '@carbon/react';
import React from 'react';

export const TextArea = React.forwardRef(({ children, ...props }, ref) => (
  <CarbonTextArea ref={ref} {...props}>
    {children}
  </CarbonTextArea>
));

TextArea.displayName = 'TextArea';
