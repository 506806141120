/* eslint-disable react/prop-types */
import { TableExpandHeader as CarbonTableExpandHeader } from '@carbon/react';
import { forwardRef } from 'react';

/**
 *
 * @param {import('@carbon/react/lib/components/DataTable/TableExpandHeader').TableExpandHeader} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonTableExpandHeader}
 */
const TableExpandHeaderForwarded = ({ ...props }, ref) => (
  <CarbonTableExpandHeader ref={ref} {...props} />
);

export const TableExpandHeader = forwardRef(TableExpandHeaderForwarded);
