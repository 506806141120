/* eslint-disable react/prop-types */
import React from 'react';
import {
  RadioButton as CarbonRadioButton,
  RadioButtonGroup as CarbonRadioButtonGroup,
} from '@carbon/react';

/**
 *
 * @param {import('@carbon/react/lib/components/RadioButtonGroup/RadioButtonGroup').RadioButtonGroupProps & { buttons: import('@carbon/react/lib/components/RadioButton/RadioButton').RadioButtonProps[]}} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonRadioButtonGroup}
 */
const RadioButtonsForwarded = ({ buttons, ...props }, ref) => (
  <CarbonRadioButtonGroup ref={ref} {...props}>
    {buttons.map((buttonProps) => (
      <CarbonRadioButton key={buttonProps.value} {...buttonProps} />
    ))}
  </CarbonRadioButtonGroup>
);

export const RadioButtons = React.forwardRef(RadioButtonsForwarded);
