/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import { DataTable as CarbonDataTable } from '@carbon/react';

/**
 *
 * @param {import('@carbon/react').DataTableProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonDataTable}
 */
export const DataTableForwarded = (props, ref) => (
  <CarbonDataTable ref={ref} {...props} />
);

export const DataTable = forwardRef(DataTableForwarded);
