import { Redo as CarbonRedo } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getRedoIcon = (size = 16) => {
  const Redo = (props) => <CarbonRedo size={size} {...props} />;

  Redo.propTypes = iconPropTypes;
  Redo.defaultProps = iconDefaultProps;

  return Redo;
};

export const Redo16 = getRedoIcon();
export const Redo20 = getRedoIcon(20);
export const Redo24 = getRedoIcon(24);
export const Redo32 = getRedoIcon(32);
