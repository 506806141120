import PropTypes from 'prop-types';

export const iconPropTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  'aria-label': PropTypes.string,
};

export const iconDefaultProps = {
  size: 16,
  className: '',
  'aria-label': '',
};
