import { Link as CarbonLink } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getLinkIcon = (size = 16) => {
  const LinkIcon = (props) => <CarbonLink size={size} {...props} />;

  LinkIcon.propTypes = iconPropTypes;
  LinkIcon.defaultProps = iconDefaultProps;

  return LinkIcon;
};

export const Link16 = getLinkIcon();
export const Link20 = getLinkIcon(20);
export const Link24 = getLinkIcon(24);
export const Link32 = getLinkIcon(32);
