import React from 'react';
import PropTypes from 'prop-types';

const GliaLogo = ({ isWhite, width }) => {
  const purpleLogo = (
    <svg
      data-test="purpleLogo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 47"
      width={width}
      height="100%"
    >
      <defs>
        <linearGradient
          x1="37.127181%"
          y1="88.7787404%"
          x2="78.6098446%"
          y2="20.9537805%"
          id="A"
        >
          <stop stopColor="#861eec" offset="0%" />
          <stop stopColor="#47018c" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="49.9178987%"
          y1="24.62076%"
          x2="19.2952515%"
          y2="88.9783463%"
          id="B"
        >
          <stop stopColor="#861eec" offset="0%" />
          <stop stopColor="#47018c" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="9.77750466%"
          y1="55.5610537%"
          x2="93.1394284%"
          y2="43.6542131%"
          id="C"
        >
          <stop stopColor="#8922ee" offset="0%" />
          <stop stopColor="#692dbe" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="evenodd">
        <path
          d="M69.846 1.58c.05.026 2.022-.5 5.92-1.58.834 3.102-1.026 6.294-4.154 7.13l-1.758.47c-.008 0-.008-6.018-.008-6.018zM60.68 3.897l5.935-1.568v26.1H60.68V3.897zM37.75 32.028l5.994-1.584c.754.706 1.836 1.175 3.546 1.175 2.77 0 4.47-1.405 4.47-4.175v-1.795c-1.306 1.6-3.205 2.536-5.776 2.536-5.618 0-9.733-4.526-9.733-9.91s4.115-9.91 9.733-9.91c2.572 0 4.47.936 5.776 2.536v-2h5.776v18.534c0 6.555-5.183 9.326-10.445 9.326-4.115 0-7.557-1.522-9.377-4.72l.035-.02zm9.223-9.228c2.81 0 4.787-1.834 4.787-4.526s-1.978-4.526-4.787-4.526-4.787 1.834-4.787 4.526 1.978 4.526 4.787 4.526zM69.8 28.42v-17.91l5.935-1.57v19.48H69.8zm23.46-19.5h5.935v19.5H93.26v-1.834c-1.306 1.483-3.244 2.38-5.895 2.38-5.183 0-9.456-4.487-9.456-10.3s4.273-10.3 9.456-10.3c2.65 0 4.59.897 5.895 2.38V8.91zm-4.708 14.515c2.73 0 4.708-1.834 4.708-4.76s-1.978-4.76-4.708-4.76-4.708 1.834-4.708 4.76 1.978 4.76 4.708 4.76z"
          fill="#2c0735"
        />
        <path
          d="M14.77 25.117c-4.078 0-7.385-3.236-7.385-7.227s3.306-7.227 7.385-7.227 7.385 3.236 7.385 7.227-3.306 7.227-7.385 7.227m9.43-18.353c.5-.515.913-1.126 1.28-1.878.7-1.456.88-3.187.424-4.864L25.9.018 11.322 3.832C4.828 5.352 0 11.07 0 17.9c0 7.983 6.612 14.455 14.77 14.455s14.77-6.472 14.77-14.455c0-4.475-2.078-8.474-5.34-11.126"
          fill="url(#A)"
        />
        <path
          d="M25.902.023L25.9.018 12.296 3.575c-.865.205-2.825.693-4.16 1.428-3.182 1.908-5.305 5.344-5.305 9.265 0 5.76 4.58 10.47 10.37 10.833.083-.006.25-.003.377-.02.067-.01.136-.02.194-.032-3.605-.478-6.385-3.5-6.385-7.16 0-3.552 2.62-6.503 6.07-7.11l7.774-2.206c2.143-.815 3.337-1.815 4.25-3.687.7-1.456.88-3.187.424-4.864"
          fill="url(#B)"
        />
        <path
          d="M21.035 33.868L6.64 37.642C2.665 38.684.3 42.666 1.36 46.536l21.593-5.66c3.975-1.042 6.338-5.024 5.278-8.894l-7.198 1.887z"
          fill="url(#C)"
        />
      </g>
    </svg>
  );
  const whiteLogo = (
    <svg
      data-test="whiteLogo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 47"
      width={width}
      height="100%"
    >
      <g fillRule="evenodd">
        <path
          d="M69.846 1.58c.05.026 2.022-.5 5.92-1.58.834 3.102-1.026 6.294-4.154 7.13l-1.758.47c-.008 0-.008-6.018-.008-6.018zM60.68 3.897l5.935-1.568v26.1H60.68V3.897zM37.75 32.028l5.994-1.584c.754.706 1.836 1.175 3.546 1.175 2.77 0 4.47-1.405 4.47-4.175v-1.795c-1.306 1.6-3.205 2.536-5.776 2.536-5.618 0-9.733-4.526-9.733-9.91s4.115-9.91 9.733-9.91c2.572 0 4.47.936 5.776 2.536v-2h5.776v18.534c0 6.555-5.183 9.326-10.445 9.326-4.115 0-7.557-1.522-9.377-4.72l.035-.02zm9.223-9.228c2.81 0 4.787-1.834 4.787-4.526s-1.978-4.526-4.787-4.526-4.787 1.834-4.787 4.526 1.978 4.526 4.787 4.526zM69.8 28.42v-17.91l5.935-1.57v19.48H69.8zm23.46-19.5h5.935v19.5H93.26v-1.834c-1.306 1.483-3.244 2.38-5.895 2.38-5.183 0-9.456-4.487-9.456-10.3s4.273-10.3 9.456-10.3c2.65 0 4.59.897 5.895 2.38V8.91zm-4.708 14.515c2.73 0 4.708-1.834 4.708-4.76s-1.978-4.76-4.708-4.76-4.708 1.834-4.708 4.76 1.978 4.76 4.708 4.76z"
          fill="#fff"
        />
        <path
          d="M14.77 25.117c-4.078 0-7.385-3.236-7.385-7.227s3.306-7.227 7.385-7.227 7.385 3.236 7.385 7.227-3.306 7.227-7.385 7.227m9.43-18.353c.5-.515.913-1.126 1.28-1.878.7-1.456.88-3.187.424-4.864L25.9.018 11.322 3.832C4.828 5.352 0 11.07 0 17.9c0 7.983 6.612 14.455 14.77 14.455s14.77-6.472 14.77-14.455c0-4.475-2.078-8.474-5.34-11.126"
          fill="#fff"
        />
        <path
          d="M25.902.023L25.9.018 12.296 3.575c-.865.205-2.825.693-4.16 1.428-3.182 1.908-5.305 5.344-5.305 9.265 0 5.76 4.58 10.47 10.37 10.833.083-.006.25-.003.377-.02.067-.01.136-.02.194-.032-3.605-.478-6.385-3.5-6.385-7.16 0-3.552 2.62-6.503 6.07-7.11l7.774-2.206c2.143-.815 3.337-1.815 4.25-3.687.7-1.456.88-3.187.424-4.864"
          fill="#fff"
        />
        <path
          d="M21.035 33.868L6.64 37.642C2.665 38.684.3 42.666 1.36 46.536l21.593-5.66c3.975-1.042 6.338-5.024 5.278-8.894l-7.198 1.887z"
          fill="#fff"
        />
      </g>
    </svg>
  );

  return <div className="glia-logo">{isWhite ? whiteLogo : purpleLogo}</div>;
};

GliaLogo.propTypes = {
  isWhite: PropTypes.bool,
  width: PropTypes.number,
};
GliaLogo.defaultProps = {
  isWhite: false,
  width: 65,
};
export { GliaLogo };
