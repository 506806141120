import { UserAvatarFilled as CarbonUserAvatarFilled } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getUserAvatarFilledIcon = (size = 16) => {
  const UserAvatarFilledIcon = (props) => (
    <CarbonUserAvatarFilled size={size} {...props} />
  );

  UserAvatarFilledIcon.propTypes = iconPropTypes;
  UserAvatarFilledIcon.defaultProps = iconDefaultProps;

  return UserAvatarFilledIcon;
};

export const UserAvatarFilled16 = getUserAvatarFilledIcon();
export const UserAvatarFilled20 = getUserAvatarFilledIcon(20);
export const UserAvatarFilled24 = getUserAvatarFilledIcon(24);
export const UserAvatarFilled32 = getUserAvatarFilledIcon(32);
