/* eslint-disable react/prop-types */
import { OverflowMenuItem as CarbonOverflowMenuItem } from '@carbon/react';
import React from 'react';

/** @typedef {import('react').ComponentProps<typeof CarbonOverflowMenuItem>} OverflowMenuItemProps */

/**
 * @param {OverflowMenuItemProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonOverflowMenuItem}
 */
const OverflowMenuItemForwarded = (props, ref) => (
  <CarbonOverflowMenuItem {...props} ref={ref} />
);

export const OverflowMenuItem = React.forwardRef(OverflowMenuItemForwarded);
