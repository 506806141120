import styled from 'styled-components';

const NotificationsRoot = styled.div`
  position: absolute;
  bottom: ${(p) => p.theme.spacing05};
  left: ${(p) => p.theme.spacing05};
  display: flex;
  flex-direction: column;
  z-index: 6001;
`;

export { NotificationsRoot };
