/* eslint-disable react/prop-types */
import { Loading as CarbonLoading } from '@carbon/react';

/** @typedef {import('react').ComponentProps<typeof CarbonLoading>} LoadingProps */

/**
 * @param {LoadingProps} props
 * @param {React.ReactNode} [children]
 */

export const Loading = ({ children, ...props }) => (
  <CarbonLoading {...props}>{children}</CarbonLoading>
);
