import { WarningAltFilled as CarbonWarningAltFilled } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getWarningAltFilledIcon = (size = 16) => {
  const WarningAltFilledIcon = (props) => (
    <CarbonWarningAltFilled size={size} {...props} />
  );

  WarningAltFilledIcon.propTypes = iconPropTypes;
  WarningAltFilledIcon.defaultProps = iconDefaultProps;

  return WarningAltFilledIcon;
};

export const WarningAltFilled16 = getWarningAltFilledIcon();
export const WarningAltFilled20 = getWarningAltFilledIcon(20);
export const WarningAltFilled24 = getWarningAltFilledIcon(24);
export const WarningAltFilled32 = getWarningAltFilledIcon(32);
