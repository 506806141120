import { UNIDENTIFIED_USER } from '../constants';
/**
 * A collection of functions to help with the user object obtained from Auth0
 */

/**
 * Get the roles of a given user
 * @param {Object} user - Auth0 user object
 * @returns  {String []} Array of roles user has
 */
const getUserRoles = (user) =>
  user[`https://${window.config.AUTH0_TENANT_PREFIX}/roles`] || [];

/**
 * Get the client IDs of a given user
 * @param {Object} user - Auth0 user object
 * @returns  {String []} Array of IDs for clients the user can access
 */
const getUserClientCodes = (user) => {
  const { portal: { cms: clientIds = [] } = {} } =
    user[`https://${window.config.AUTH0_TENANT_PREFIX}/user_metadata`] || {};
  return clientIds;
};

/**
 * Safely get a user's name
 * @param {string} defaultName - The default name to use if the user's name is not available
 * @returns {(user: object | null | undefined) => string} The user's name or default
 */
const getUserNameOrDefault = (defaultName) => (user) =>
  user?.name ?? defaultName;

/**
 * Safely get a user's name; fall back to UNIDENTIFIED_USER if not available
 * @param {object | null | undefined} user - The user object
 * @returns {string} The user's name or UNIDENTIFIED_USER
 */
const getUserNameOrUnidentified = getUserNameOrDefault(UNIDENTIFIED_USER);

export { getUserRoles, getUserClientCodes, getUserNameOrUnidentified };
