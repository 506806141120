import { UserProfile as CarbonUserProfile } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getUserProfileIcon = (size = 16) => {
  const UserProfileIcon = (props) => (
    <CarbonUserProfile size={size} {...props} />
  );

  UserProfileIcon.propTypes = iconPropTypes;
  UserProfileIcon.defaultProps = iconDefaultProps;

  return UserProfileIcon;
};

export const UserProfile16 = getUserProfileIcon();
export const UserProfile20 = getUserProfileIcon(20);
export const UserProfile24 = getUserProfileIcon(24);
export const UserProfile32 = getUserProfileIcon(32);
