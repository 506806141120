import { FilterRemove as CarbonFilterRemove } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getFilterRemoveIcon = (size = 16) => {
  const FilterRemoveIcon = (props) => (
    <CarbonFilterRemove size={size} {...props} />
  );

  FilterRemoveIcon.propTypes = iconPropTypes;
  FilterRemoveIcon.defaultProps = iconDefaultProps;

  return FilterRemoveIcon;
};

export const FilterRemove16 = getFilterRemoveIcon();
export const FilterRemove20 = getFilterRemoveIcon(20);
export const FilterRemove24 = getFilterRemoveIcon(24);
export const FilterRemove32 = getFilterRemoveIcon(32);
