import { UserAvatarFilledAlt as CarbonUserAvatarFilledAlt } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getUserAvatarFilledAltIcon = (size = 16) => {
  const UserAvatarFilledAltIcon = (props) => (
    <CarbonUserAvatarFilledAlt size={size} {...props} />
  );

  UserAvatarFilledAltIcon.propTypes = iconPropTypes;
  UserAvatarFilledAltIcon.defaultProps = iconDefaultProps;

  return UserAvatarFilledAltIcon;
};

export const UserAvatarFilledAlt16 = getUserAvatarFilledAltIcon();
export const UserAvatarFilledAlt20 = getUserAvatarFilledAltIcon(20);
export const UserAvatarFilledAlt24 = getUserAvatarFilledAltIcon(24);
export const UserAvatarFilledAlt32 = getUserAvatarFilledAltIcon(32);
