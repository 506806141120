import startCase from 'lodash.startcase';
import { getFeatureFlags } from './helpers/featureFlags';

const { FIXED_USER_GOALS_ENABLED = false } = getFeatureFlags() || {};

export const MINIMUM_AUTOCOMPLETE_SEARCH_STRING_LENGTH = 3;
export const AUTOCOMPLETE_DEBOUNCE_TIMEOUT = 200;

export const BUTTON_META_TYPE_FIXED = 'Fixed';
export const BUTTON_META_TYPE_FLEX = 'Flex';
export const BUTTON_META_TYPE_URL_LINK = 'URL Link';
export const BUTTON_META_TYPE_PHONE_NUMBER = 'Phone Number';
export const BUTTON_META_TYPE_CUSTOMER_SERVICE_TEAM = 'Customer Service Team';
export const BUTTON_META_TYPE_BROADCAST_EVENT = 'Broadcast Event';
export const BUTTON_META_TYPE_TRANSFER_PHONE_NUMBER = 'Transfer Phone Number';
export const BUTTON_META_TYPE_FUNCTION_INVOCATION = 'Function Invocation';
export const BUTTON_META_TYPE_CUSTOM_PAYLOAD = 'Custom Payload';

export const PERSISTENT_BUTTON_META_TYPES = [
  BUTTON_META_TYPE_FIXED,
  BUTTON_META_TYPE_FLEX,
  BUTTON_META_TYPE_URL_LINK,
  BUTTON_META_TYPE_PHONE_NUMBER,
  BUTTON_META_TYPE_BROADCAST_EVENT,
];

export const QUICK_REPLY_META_TYPES = [
  BUTTON_META_TYPE_FIXED,
  BUTTON_META_TYPE_FLEX,
  BUTTON_META_TYPE_CUSTOMER_SERVICE_TEAM,
  BUTTON_META_TYPE_TRANSFER_PHONE_NUMBER,
  ...(FIXED_USER_GOALS_ENABLED
    ? [BUTTON_META_TYPE_FUNCTION_INVOCATION, BUTTON_META_TYPE_CUSTOM_PAYLOAD]
    : []),
];

export const BUTTON_TYPE_PERSISTENT_BUTTON = 'Persistent Button';
export const BUTTON_TYPE_QUICK_REPLY = 'Quick Reply';
export const BUTTON_TYPE_ABBREVIATED_PERSISTENT_BUTTON = 'PB';
export const BUTTON_TYPE_ABBREVIATED_QUICK_REPLY = 'QR';

export const BUTTON_FIELD_ID = 'id';
export const BUTTON_FIELD_TYPE = 'type';
export const BUTTON_FIELD_LABEL = 'label';
export const BUTTON_FIELD_META_TYPE = 'metaType';
export const BUTTON_FIELD_META_DESTINATION_LABEL = 'metaDestinationLabel';
export const BUTTON_FIELD_FIXED_RESPONSE = 'fixedResponse';
export const BUTTON_FIELD_FLEX_UTTERANCE = 'flexUtterance';
export const BUTTON_FIELD_PHONE_NUMBER = 'phoneNumber';
export const BUTTON_FIELD_TRANSFER_PHONE_NUMBER = 'transferPhoneNumber';
export const BUTTON_FIELD_URL = 'url';
export const BUTTON_FIELD_URL_OPTION = 'urlOption';
export const BUTTON_FIELD_CUSTOMER_SERVICE_CODE = 'customerServiceCode';
export const BUTTON_FIELD_USE_AS_CALLBACK = 'useAsCallback';
export const BUTTON_FIELD_SOURCE_RESPONSES = 'sourceResponses';
export const BUTTON_FIELD_BROADCAST_EVENT_NAME = 'broadcastEventName';
export const BUTTON_FIELD_SOURCE_GALLERY_ITEMS = 'sourceGalleryItems';
export const BUTTON_FIELD_CUSTOM_PAYLOAD = 'customPayload';
export const BUTTON_FIELD_FUNCTION_INVOCATION = 'functionInvocation';

export const DEFAULT_PAGE_SIZE = 25;
export const GALLERY_PAGE_SIZE = 24;
export const MIN_COLUMN_WIDTH = 60;
export const MAX_COLUMN_WIDTH = 600;

export const MAX_LENGTH = {
  // button
  label: 100,
  flexUtterance: 300,
  url: 500,
  phoneNumber: 20,
  transferPhoneNumber: 50,
  customerServiceCode: 50,
  broadcastEventName: 100,
  // response
  text: 3000,
  // gallery card
  title: 500,
  subtitle: 2000,
};

export const MAX_LENGTH_ERROR_MSG = (field) =>
  `Your input is too long. Please make sure that it does not exceed ${MAX_LENGTH[field]} characters.`;
export const INVALID_URL_ERROR_MSG =
  'That looks like an invalid public URL. Please verify that it is correct by opening it first in a separate browser window.';
export const INVALID_IMAGE_EXT_ERROR_MSG =
  'That looks like an invalid image file extension. Please use any of these image extensions (jpg, jpeg, png, webp, avif, gif).';
export const INVALID_IMAGE_ERROR_MSG =
  'That URL did not resolve in a valid image. Please verify that it is correct.';
export const INVALID_UUID_MSG =
  "The format of the Queue ID you entered doesn't look right. Please make sure you provide Queue ID that can be found in Queue settings in Glia Hub. Usually, Queue IDs look like this: 0189b885-5065-4da3-9b26-dcf74bfe2dc2.";
export const INVALID_IMG_FILE_ERROR_MSG =
  'That looks like an invalid image file, please remove it and try again.';
export const TEMPLATE_INJECTION_ERROR_MSG =
  'Your input contains an invalid function call. Please remove it and try again.';
export const IMAGE_URL_TEMPLATE_VALIDATION_MSG =
  'Your image URL contains a template. Templates will be validated when a new Gallery Card or changes to an existing Gallery Card are submitted. Cards with unapproved templates will be rejected.';
export const INVALID_FUNCTION_INVOCATION_URI_MSG =
  'Please enter a valid Function Invocation URI.';
export const VALID_FUNCTION_INVOCATION_URI_PLACEHOLDER =
  '/integrations/e0b8bb94-1b1c-41a4-b862-2907cbaa0501/endpoint';
export const NO_SELECTED_INTENTS_ERROR_MSG =
  "PUGG items need to have at least one user goal. If you'd like to remove all user goals associated with this item, please delete the item itself.";

export const PRODUCT_NAME_ERROR_MSG =
  'Product/Service name already exists, please try again.';
export const INVALID_SYMBOL_ERROR_MSG = 'Please avoid using symbols';
export const SYNONYM_ERROR_MSG = (clashEntityValue) =>
  `Synonym is already used for ${startCase(
    clashEntityValue
  )}, please try again.`;
export const SYNONYM_CASE_INSENSITIVE_DUPLICATE_ERROR_MSG = (duplicate) =>
  `${duplicate} has already been added, please try entering another synonym. Note: Synonyms are case-insensitive.`;
export const SYNONYM_GENERIC_ERROR_MESSAGE =
  'An error occured, please try again';

export const CLIENT_RESPONSES_BY_USER_GOAL_QUERY_LIMIT = 25;
export const CLIENT_BUTTONS_QUERY_LIMIT = 25;
export const CLIENT_GALLERY_CARDS_QUERY_LIMIT = 25;
export const LINKED_GALLERY_CARDS_LIMIT = 25;

export const FINN_STATUS_DONE = 'Done';
export const CUSTOMER_STATUS_DRAFT = 'Draft';
export const CUSTOMER_STATUS_EXPORTED = 'Exported';
export const CONVERT_TO_DRAFT_STATUS = ['Default', 'Approved', 'Exported'];
export const PUBLISHABLE_STATUSES = ['Default', 'Approved', 'Exported'];
export const HIDDEN_DROPDOWN_STATUS = ['Exported', 'Default'];
// TODO: Move this to be defined in Admin UI per customer and only have default values here.
export const CUSTOMER_STATUS_COLOURS = {
  Draft: 'magenta',
  Default: 'purple',
  Exported: 'green',
  Approved: 'teal',
};

export const BUSINESS_OBJECT_LABEL = 'Business Object';

// DEFAULT_SEARCH_FILTER_OPTION and SEARCH_FILTER_OPTIONS for search bar.
export const DEFAULT_SEARCH_FILTER_OPTION = {
  id: 'text',
  label: 'Response Text',
};

export const CONTENT_SEARCH_FILTER_OPTIONS = [
  {
    id: 'text',
    label: 'Response Text',
  },
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'userGoalName',
    label: 'User Goal Name',
  },
  {
    id: 'userGoalDescription',
    label: 'User Goal Description',
  },
  {
    id: 'chatDriver',
    label: 'Chat Driver',
  },
  {
    id: 'group',
    label: 'Group',
  },
  {
    id: 'responseLinks',
    label: 'Response Links',
  },
  {
    id: 'businessObject',
    label: BUSINESS_OBJECT_LABEL,
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'tag',
    label: 'Tag',
  },
  {
    id: 'assignee',
    label: 'Assignee',
  },
];

/**
 * This is simply the object version of `CONTENT_SEARCH_FILTER_OPTIONS`
 * for O(1) lookup.
 *
 * {
 *   [id]: label
 * }
 */
export const CONTENT_SEARCH_FILTER_ID_LABEL_MATCHING =
  CONTENT_SEARCH_FILTER_OPTIONS.reduce((acc, { id, label }) => {
    acc[id] = label;
    return acc;
  }, {});

export const CELL_SOURCE_IDS = {
  PLACEMENT_IN_RESPONSES_ID: 'placementInResponses',
  PLACEMENT_IN_GALLERIES_ID: 'placementInGalleries',
};

export const CELL_BUTTONS_SEARCH_FILTER_OPTIONS = [
  {
    id: 'label',
    label: 'Label',
  },
  {
    id: 'buttonType',
    label: 'Button Type',
  },
  {
    id: 'destination',
    label: 'Destination',
  },
  {
    id: CELL_SOURCE_IDS.PLACEMENT_IN_RESPONSES_ID,
    label: 'Placement In Responses',
  },
  {
    id: CELL_SOURCE_IDS.PLACEMENT_IN_GALLERIES_ID,
    label: 'Placement in Galleries',
  },
];

export const CELL_BUTTONS_COLUMN_WIDTHS = {
  label: '150px',
  type: '64px',
  buttonType: '150px',
  destination: '250px',
  [CELL_SOURCE_IDS.PLACEMENT_IN_RESPONSES_ID]: '300px',
  [CELL_SOURCE_IDS.PLACEMENT_IN_GALLERIES_ID]: '300px',
};

export const CELL_BUTTONS_SEARCH_FILTER_ID_LABEL_MATCHING =
  CELL_BUTTONS_SEARCH_FILTER_OPTIONS.reduce((acc, { id, label }) => {
    acc[id] = label;
    return acc;
  }, {});

export const CELL_GALLERIES_SEARCH_FILTER_OPTIONS = [
  {
    id: 'title',
    label: 'Title',
  },
  {
    id: 'subtitle',
    label: 'Subtitle',
  },
  {
    id: 'image',
    label: 'Image Name',
  },
  {
    id: 'button',
    label: 'Button Label',
  },
  {
    id: 'placementInResponses',
    label: 'Placement In Responses',
  },
];

export const CELL_GALLERIES_SEARCH_FILTER_ID_LABEL_MATCHING =
  CELL_GALLERIES_SEARCH_FILTER_OPTIONS.reduce((acc, { id, label }) => {
    acc[id] = label;
    return acc;
  }, {});

export const TO_CONTAIN_DROPDOWN = [
  { label: 'Contains', id: true },
  { label: 'Does not contain', id: false },
];

export const RESPONSE_HISTORY_FIELD_NAME = {
  ASSIGNEE: 'Assignee',
  QUICK_REPLY: 'Quick Reply',
  GALLERY_ITEM: 'Gallery Item',
  RESPONSE_LINK: 'Response Link',
  RESPONSE_TEXT: 'Response Text',
  CUSTOMER_STATUS: 'Customer Status',
  PERSISTENT_BUTTON: 'Persistent Button',
  BROADCAST_EVENT: 'Broadcast Event',
  REQUIRES_AUTH: 'Force Authentication',
};

export const RESPONSE_FIELD = {
  STATUS: 'status',
  LOCALE: 'locale',
  CHANNEL: 'channel',
};

export const RESPONSE_HISTORY_EVENT_NOTES = {
  BATCH_EDIT: 'Via batch editing',
  STATUS_CHANGE: 'Automatically triggered',
};

export const ENVIRONMENTS = {
  UAT: 'UAT',
  PRODUCTION: 'PRODUCTION',
};

export const ALIGN = {
  TOP: 'top',
  TOP_LEFT: 'top-left',
  TOP_TOP: 'top-right',
  BOTTOM: 'bottom',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
  LEFT: 'left',
  LEFT_TOP: 'left-top',
  LEFT_BOTTOM: 'left-bottom',
  RIGHT: 'right',
  RIGHT_TOP: 'right-top',
  RIGHT_BOTTOM: 'right-bottom',
};

export const HISTORY_EVENT_TYPE = {
  EXPORT: 'ExportHistoryEvent',
};

export const NOTIFICATION_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  LOADING: 'loading',
  UNAUTHORIZED: 'unauthorized',
  TEMPLATE_ERROR: 'template_error',
};

// these fields are excluded from being parsed as search params in the url
export const URL_PARAM_EXCLUDE_LIST = ['locale', 'channel', 'responseId'];

export const KEY = {
  ENTER: 'Enter',
};

export const INVALID_ID_CHAR_SEARCH = ['e', 'E', '+', '-'];

export const CONTENT_EXPORT_INTERNAL_ERROR =
  'Looks like there was a temporary issue with the export. Please try again in a few minutes';

export const CATEGORY_INTENT_EVENTS_AND_PROMOTIONS = 'Events and Promotions';
export const CATEGORY_TITLES_AND_DEEDS = 'Titles and Deeds';
export const CATEGORY_DIGITAL_PAYMENTS_TRANSFERS =
  'Digital Payments and Transfers';
export const CATEGORY_ADVANCED_KNOWLEDGE = 'Advanced Knowledge';
export const CATEGORY_BUSINESS_CHECKING = 'Business Checking';
export const CATEGORY_BUSINESS_SAVINGS = 'Business Savings';
export const CATEGORY_BUSINESS_LINES_OF_CREDIT = 'Business Lines of Credit';
export const CATEGORY_BUSINESS_CREDIT_CARDS = 'Business Credit Cards';
export const CATEGORY_BUSINESS_LOANS = 'Business Loans';
export const CATEGORY_COMMERCIAL_MORTGAGES = 'Commercial Mortgages';
export const CATEGORY_INVESTMENT_ACCOUNTS = 'Investment Accounts';

export const CATEGORY_INTENTS = {
  'Retail Checking': {
    intents: [
      'i.fai.limits',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.open-apply-getstarted',
      'i.fai.doyouoffer',
      'i.fai.product.howitworks',
      'i.fai.product.eligibility-minimumrequirements',
    ],
    domains: ['banking'],
  },
  'Retail Savings': {
    intents: [
      'i.fai.limits',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.open-apply-getstarted',
      'i.fai.doyouoffer',
      'i.fai.product.howitworks',
      'i.fai.product.eligibility-minimumrequirements',
    ],
    domains: ['banking'],
  },
  'Retail Credit Cards': {
    intents: [
      'i.fai.limits',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.open-apply-getstarted',
      'i.fai.doyouoffer',
      'i.fai.product.howitworks',
      'i.fai.product.eligibility-minimumrequirements',
    ],
    domains: ['banking'],
  },
  'Retail Debit Cards': {
    intents: [
      'i.fai.limits',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.open-apply-getstarted',
      'i.fai.doyouoffer',
      'i.fai.product.howitworks',
      'i.fai.product.eligibility-minimumrequirements',
    ],
    domains: ['banking'],
  },
  'Retail Loans': {
    intents: [
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.open-apply-getstarted',
      'i.fai.doyouoffer',
      'i.fai.product.howitworks',
      'i.fai.product.eligibility-minimumrequirements',
    ],
    domains: ['banking'],
  },
  'Retail Lines of Credit': {
    intents: [
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.open-apply-getstarted',
      'i.fai.doyouoffer',
      'i.fai.product.howitworks',
      'i.fai.product.eligibility-minimumrequirements',
    ],
    domains: ['banking'],
  },
  'Retail Mortgages': {
    intents: [
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.open-apply-getstarted',
      'i.fai.doyouoffer',
      'i.fai.product.howitworks',
      'i.fai.product.eligibility-minimumrequirements',
    ],
    domains: ['banking'],
  },
  'Retail Others': {
    intents: [
      'i.fai.limits',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.open-apply-getstarted',
      'i.fai.doyouoffer',
      'i.fai.product.howitworks',
      'i.fai.product.eligibility-minimumrequirements',
    ],
    domains: ['banking'],
  },
  'Tax Documents': {
    intents: [
      'i.fai.timetoprocess',
      'i.fai.my.accountinfo',
      'i.fai.techissues.accountaccess',
      'i.fai.accountaccess.howto',
      'i.fai.export',
      'i.fai.techissues.general',
      'i.fai.notarrived',
      'i.fai.change.accountinfo',
    ],
    domains: ['banking'],
  },
  [CATEGORY_INTENT_EVENTS_AND_PROMOTIONS]: {
    intents: [
      'i.fai.timetoprocess',
      'i.fai.promo-specialoffer',
      'i.fai.doyouoffer',
      'i.fai.open-apply-getstarted',
      'i.fai.product.howitworks',
      'i.fai.product.close-cancel',
    ],
    domains: ['banking', 'generic'],
    showEmpty: true,
  },
  [CATEGORY_TITLES_AND_DEEDS]: {
    intents: [
      'i.fai.my.accountinfo',
      'i.fai.timetoprocess',
      'i.fai.notarrived',
      'i.fai.checkstatusof',
      'i.fai.moneymovement.howto',
    ],
    domains: ['banking', 'generic'],
    showEmpty: true,
  },
  [CATEGORY_DIGITAL_PAYMENTS_TRANSFERS]: {
    intents: [
      'i.fai.linking',
      'i.fai.moneymovement.cancel',
      'i.fai.product.howitworks',
      'i.fai.techissues.general',
      'i.fai.limits',
      'i.fai.moneymovement.howto',
      'i.fai.notarrived',
      'i.fai.doyouoffer',
      'i.fai.timetoprocess',
      'i.fai.open-apply-getstarted',
      'i.fai.digitalwallet.manage',
      'i.fai.change.limit',
    ],
    domains: ['banking', 'generic'],
    showEmpty: true,
  },
  [CATEGORY_ADVANCED_KNOWLEDGE]: {
    intents: [
      'i.fai.open-apply-getstarted',
      'i.fai.my.accountinfo',
      'i.fai.feesandcharges',
      'i.fai.doyouoffer',
      'i.fai.product.ordernew',
      'i.fai.checkstatusof',
      'i.fai.product.howitworks',
      'i.fai.change.limit',
      'i.fai.export',
      'i.fai.promo-specialoffer',
      'i.fai.techissues.general',
      'i.fai.timetoprocess',
      'i.fai.notarrived',
      'i.fai.techissues.accountaccess',
      'i.fai.define',
      'i.fai.who-is',
      'i.fai.redeem.howto',
    ],
    domains: ['banking', 'generic'],
    showEmpty: true,
  },
  [CATEGORY_BUSINESS_CHECKING]: {
    intents: [
      'i.fai.product.eligibility-minimumrequirements',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.limits',
      'i.fai.product.howitworks',
      'i.fai.doyouoffer',
      'i.fai.promo-specialoffer',
      'i.fai.product.documentsrequiredtoapply',
      'i.fai.open-apply-getstarted',
    ],
    domains: ['banking'],
    showEmpty: true,
  },
  [CATEGORY_BUSINESS_SAVINGS]: {
    intents: [
      'i.fai.product.eligibility-minimumrequirements',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.limits',
      'i.fai.product.howitworks',
      'i.fai.doyouoffer',
      'i.fai.promo-specialoffer',
      'i.fai.product.documentsrequiredtoapply',
      'i.fai.open-apply-getstarted',
    ],
    domains: ['banking'],
    showEmpty: true,
  },
  [CATEGORY_BUSINESS_LINES_OF_CREDIT]: {
    intents: [
      'i.fai.product.eligibility-minimumrequirements',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.limits',
      'i.fai.product.howitworks',
      'i.fai.doyouoffer',
      'i.fai.promo-specialoffer',
      'i.fai.product.documentsrequiredtoapply',
      'i.fai.open-apply-getstarted',
    ],
    domains: ['banking'],
    showEmpty: true,
  },
  [CATEGORY_BUSINESS_CREDIT_CARDS]: {
    intents: [
      'i.fai.product.eligibility-minimumrequirements',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.limits',
      'i.fai.product.howitworks',
      'i.fai.doyouoffer',
      'i.fai.promo-specialoffer',
      'i.fai.product.documentsrequiredtoapply',
      'i.fai.open-apply-getstarted',
    ],
    domains: ['banking'],
    showEmpty: true,
  },
  [CATEGORY_BUSINESS_LOANS]: {
    intents: [
      'i.fai.product.eligibility-minimumrequirements',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.limits',
      'i.fai.product.howitworks',
      'i.fai.doyouoffer',
      'i.fai.promo-specialoffer',
      'i.fai.product.documentsrequiredtoapply',
      'i.fai.open-apply-getstarted',
    ],
    domains: ['banking'],
    showEmpty: true,
  },
  [CATEGORY_COMMERCIAL_MORTGAGES]: {
    intents: [
      'i.fai.product.eligibility-minimumrequirements',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.limits',
      'i.fai.product.howitworks',
      'i.fai.doyouoffer',
      'i.fai.promo-specialoffer',
      'i.fai.product.documentsrequiredtoapply',
      'i.fai.open-apply-getstarted',
    ],
    domains: ['banking'],
    showEmpty: true,
  },
  [CATEGORY_INVESTMENT_ACCOUNTS]: {
    intents: [
      'i.fai.product.eligibility-minimumrequirements',
      'i.fai.interestrates',
      'i.fai.feesandcharges',
      'i.fai.limits',
      'i.fai.product.howitworks',
      'i.fai.doyouoffer',
      'i.fai.promo-specialoffer',
      'i.fai.product.documentsrequiredtoapply',
      'i.fai.open-apply-getstarted',
    ],
    domains: ['banking'],
    showEmpty: true,
  },
};

export const INTENT_DESCRIPTION = {
  'i.fai.limits': {
    description:
      'The user would like to know the limits for the <Banking Object>.',
    categories: {
      [CATEGORY_INTENT_EVENTS_AND_PROMOTIONS]: {
        description:
          'The user is wondering what their (sending or receiving) <Banking Object> limit is.',
      },
      [CATEGORY_DIGITAL_PAYMENTS_TRANSFERS]: {
        description:
          'The user is wondering what their (sending or receiving) <Banking Object> limit is.',
      },
    },
  },
  'i.fai.interestrates': {
    description:
      'The user would like to know what the interest rates are for <Banking Object>.',
  },
  'i.fai.feesandcharges': {
    description:
      'The user would like to know the fees and charges for <Banking Object>.',
    categories: {
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are enquiring about costs: they would like to know the fees and charges for their <Banking Object>.',
      },
    },
  },
  'i.fai.open-apply-getstarted': {
    description: 'The user would like to open or apply for a <Banking Object>.',
    categories: {
      [CATEGORY_INTENT_EVENTS_AND_PROMOTIONS]: {
        description:
          'The user would like to know how to get started with <Banking Object>.',
      },
      [CATEGORY_DIGITAL_PAYMENTS_TRANSFERS]: {
        description:
          'The user would like to set up or sign up for or enroll in <Banking Object>.',
      },
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are asking what <Banking Object> process looks like: how to request, apply, or start one.',
      },
      [CATEGORY_BUSINESS_CHECKING]: {
        description:
          'The user would like to open a <Banking Object> with your institution.',
      },
      [CATEGORY_BUSINESS_SAVINGS]: {
        description:
          'The user would like to open a <Banking Object> with your institution.',
      },
      [CATEGORY_BUSINESS_LINES_OF_CREDIT]: {
        description:
          'The user would like to open a <Banking Object> with your institution.',
      },
      [CATEGORY_BUSINESS_CREDIT_CARDS]: {
        description:
          'The user would like to open a <Banking Object> with your institution.',
      },
      [CATEGORY_BUSINESS_LOANS]: {
        description:
          'The user would like to open a <Banking Object> with your institution.',
      },
      [CATEGORY_COMMERCIAL_MORTGAGES]: {
        description:
          'The user would like to open a <Banking Object> with your institution.',
      },
      [CATEGORY_INVESTMENT_ACCOUNTS]: {
        description:
          'The user would like to open a <Banking Object> with your institution.',
      },
    },
  },
  'i.fai.doyouoffer': {
    description:
      'The user would like to know if you offer the <Banking Object>.',
    categories: {
      [CATEGORY_INTENT_EVENTS_AND_PROMOTIONS]: {
        description:
          'The user would like to know if your organization offers <Banking Object>.',
      },
      [CATEGORY_DIGITAL_PAYMENTS_TRANSFERS]: {
        description:
          'The user would like to know if you offer or support <Banking Object>.',
      },
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are asking whether <Banking Object> exists, or what <Banking Object> options are available to them.',
      },
      [CATEGORY_BUSINESS_CHECKING]: {
        description:
          'The user would like to know if you offer <Banking Object>.',
      },
      [CATEGORY_BUSINESS_SAVINGS]: {
        description:
          'The user would like to know if you offer <Banking Object>.',
      },
      [CATEGORY_BUSINESS_LINES_OF_CREDIT]: {
        description:
          'The user would like to know if you offer <Banking Object>.',
      },
      [CATEGORY_BUSINESS_CREDIT_CARDS]: {
        description:
          'The user would like to know what <Banking Object> your institution offers.',
      },
      [CATEGORY_BUSINESS_LOANS]: {
        description:
          'The user would like to know if you offer <Banking Object>.',
      },
      [CATEGORY_COMMERCIAL_MORTGAGES]: {
        description:
          'The user would like to know if you offer <Banking Object>.',
      },
      [CATEGORY_INVESTMENT_ACCOUNTS]: {
        description:
          'The user would like to know if you offer <Banking Object>.',
      },
    },
  },
  'i.fai.product.howitworks': {
    description:
      'The user would like to learn about the features and benefits of the <Banking Object>.',
    categories: {
      [CATEGORY_INTENT_EVENTS_AND_PROMOTIONS]: {
        description:
          'The user would like to learn about the features and benefits of the <Banking Object>.',
      },
      [CATEGORY_DIGITAL_PAYMENTS_TRANSFERS]: {
        description:
          'The user would like to learn about the features and benefits of <Banking Object>.',
      },
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are enquiring about <Banking Object>: they would like more information, such as features, benefits and other details.',
      },
    },
  },
  'i.fai.product.eligibility-minimumrequirements': {
    description:
      'The user would like to know if they are eligible to apply for the <Banking Object>.\n\nThis includes: age, income, savings, debt, and residence.',
  },
  'i.fai.timetoprocess': {
    description:
      'The user wants to know when <Banking Object> will be available, either online, on the mobile app, or by mail.',
    categories: {
      [CATEGORY_INTENT_EVENTS_AND_PROMOTIONS]: {
        description:
          'The user would like to know when a <Banking Object> will happen or be available.',
      },
      [CATEGORY_TITLES_AND_DEEDS]: {
        description:
          'The user would like to know when they will receive their <Banking Object> once the loan has been paid off.',
      },
      [CATEGORY_DIGITAL_PAYMENTS_TRANSFERS]: {
        description:
          'The user would like to know how long <Banking Object> transfers take to process.',
      },
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are asking questions around dates/times/schedules/duration for <Banking Object>.',
      },
    },
  },
  'i.fai.my.accountinfo': {
    description:
      'The user is looking for their <Banking Object>, which may also include those from a closed account.',
    categories: {
      [CATEGORY_TITLES_AND_DEEDS]: {
        description:
          'The user is looking for or requesting a copy of their <Banking Object>.',
      },
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are looking for <Banking Object>: they would like to know where and how to find it.',
      },
    },
  },
  'i.fai.techissues.accountaccess': {
    description:
      'The user is having difficulties accessing their <Banking Object>, including the most recent ones or those from previous years.',
    categories: {
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are having difficulties accessing their <Banking Object> and need help.',
      },
    },
  },
  'i.fai.accountaccess.howto': {
    description:
      'The user wants to know how to access their <Banking Object>, whether it is available online, on the mobile app, or if it will be sent to them via mail.',
  },
  'i.fai.export': {
    description:
      'The user wants to print, download, and/or save their <Banking Object>.',
    categories: {
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are asking if and how it is possible to export/print/download/save or get copies of their <Banking Object>.',
      },
    },
  },
  'i.fai.techissues.general': {
    description:
      'The user is having difficulties viewing, printing, downloading, saving and finding their <Banking Object>, including both the most current and older ones.',
    categories: {
      [CATEGORY_DIGITAL_PAYMENTS_TRANSFERS]: {
        description:
          'The user is having trouble with <Banking Object> and would like help resolving it.',
      },
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are experiencing issues with their <Banking Object> and need help solving them.',
      },
    },
  },
  'i.fai.notarrived': {
    description: 'The <Banking Object> of the user did not arrive.',
    categories: {
      [CATEGORY_TITLES_AND_DEEDS]: {
        description: 'The user did not receive their <Banking Object>.',
      },
      [CATEGORY_DIGITAL_PAYMENTS_TRANSFERS]: {
        description:
          'The <Banking Object> transfer of the user was not received.',
      },
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users have not yet received/are waiting to receive their <Banking Object> and would like to have updates on that.',
      },
    },
  },
  'i.fai.change.accountinfo': {
    description: 'The user would like to update their <Banking Object>.',
  },
  'i.fai.promo-specialoffer': {
    description:
      'The user would like to know about the <Banking Object> at your organization.',
    categories: {
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are asking about events, promotions or special offers that you are offering for <Banking Object>.',
      },
      [CATEGORY_BUSINESS_CHECKING]: {
        description:
          'The user would like to know about current promotions or special offers for <Banking Object>.',
      },
      [CATEGORY_BUSINESS_SAVINGS]: {
        description:
          'The user would like to know about current promotions or special offers for <Banking Object>.',
      },
      [CATEGORY_BUSINESS_LINES_OF_CREDIT]: {
        description:
          'The user would like to know about current promotions or special offers for <Banking Object>.',
      },
      [CATEGORY_BUSINESS_CREDIT_CARDS]: {
        description:
          'The user would like to know about current promotions or special offers for <Banking Object>.',
      },
      [CATEGORY_BUSINESS_LOANS]: {
        description:
          'The user would like to know about current promotions or special offers for <Banking Object>.',
      },
      [CATEGORY_COMMERCIAL_MORTGAGES]: {
        description:
          'The user would like to know about current promotions or special offers for <Banking Object>.',
      },
      [CATEGORY_INVESTMENT_ACCOUNTS]: {
        description:
          'The user would like to know about current promotions or special offers for <Banking Object>.',
      },
    },
  },
  'i.fai.product.close-cancel': {
    description:
      'The user would like to cancel their registration from the <Banking Object>.',
  },
  'i.fai.checkstatusof': {
    description:
      'The user would like to know the status of their <Banking Object>.',
    categories: {
      [CATEGORY_TITLES_AND_DEEDS]: {
        description:
          'The user would like to know the status of their <Banking Object>.',
      },
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are enquiring about the status of their <Banking Object>: they would like updates or how to check the status of their <Banking Object>.',
      },
    },
  },
  'i.fai.moneymovement.howto': {
    description: 'The user would like to request a <Banking Object> transfer.',
    categories: {
      [CATEGORY_DIGITAL_PAYMENTS_TRANSFERS]: {
        description:
          'The user would like to know how to do a <Banking Object> transfer.',
      },
    },
  },
  'i.fai.linking': {
    description:
      'The user would like to link <Banking Object> to their account or card.',
  },
  'i.fai.moneymovement.cancel': {
    description: 'The user would like to cancel a <Banking Object> transfer.',
  },
  'i.fai.digitalwallet.manage': {
    description:
      'The user would like to manage their <Banking Object> settings, including setting up a new <Banking Object>.',
  },
  'i.fai.change.limit': {
    description: 'The user would like to change their <Banking Object> limit.',
    categories: {
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are asking whether it is possible to modify their <Banking Object> limit and, if so, how to do that.',
      },
    },
  },
  'i.fai.product.ordernew': {
    description: 'The user wants to know how to order new <Banking Object>.',
    categories: {
      [CATEGORY_ADVANCED_KNOWLEDGE]: {
        description:
          'Users are enquiring about getting a new <Banking Object>: how to order or request one.',
      },
    },
  },
  'i.fai.define': {
    description: 'Users are asking for the definition of <Banking Object>.',
  },
  'i.fai.who-is': {
    description:
      'Users are asking about who holds the role of <Banking Object> at your organization.',
  },
  'i.fai.redeem.howto': {
    description: 'Users are asking about how to redeem <Banking Object>.',
  },
  'i.fai.product.documentsrequiredtoapply': {
    description:
      'The user wants to obtain a list of physical documents or objects required to apply/register for a new <Banking Object>. This includes: cell phone, ID, proof of address, and proof of employment.',
  },
};

export const CATEGORY_ORDER_BY = [
  CATEGORY_DIGITAL_PAYMENTS_TRANSFERS,
  CATEGORY_INTENT_EVENTS_AND_PROMOTIONS,
  'Tax Documents',
  CATEGORY_TITLES_AND_DEEDS,
  'Retail Checking',
  'Retail Savings',
  'Retail Lines of Credit',
  'Retail Credit Cards',
  'Retail Loans',
  'Retail Mortgages',
  'Retail Others',
  CATEGORY_BUSINESS_CHECKING,
  CATEGORY_BUSINESS_SAVINGS,
  CATEGORY_BUSINESS_LINES_OF_CREDIT,
  CATEGORY_BUSINESS_CREDIT_CARDS,
  CATEGORY_BUSINESS_LOANS,
  CATEGORY_COMMERCIAL_MORTGAGES,
  CATEGORY_INVESTMENT_ACCOUNTS,
  CATEGORY_ADVANCED_KNOWLEDGE,
];

export const GENERIC_ERROR_MESSAGE = (text) =>
  `Something unexpected happened and we ${text}. Please check your network connection and try again.`;

// 512_000 Bytes --> 512KB
export const MAX_IMAGE_SIZE = 512_000;

export const API_VERSIONS = { V1: 'v1', V2: 'v2' };

export const UNIDENTIFIED_USER = 'Deactivated User';

export const CONTENT_REPHRASE_MODES = {
  Rephrase: [
    {
      displayText: 'Longer',
      value: 'long',
    },
    {
      displayText: 'Shorter',
      value: 'short',
    },
    {
      displayText: 'Polite',
      value: 'polite',
    },
    {
      displayText: 'Confident',
      value: 'confident',
    },
    {
      displayText: 'Formal',
      value: 'formal',
    },
    {
      displayText: 'Casual',
      value: 'casual',
    },
  ],
  Reformat: [
    {
      displayText: 'Paragraph',
      value: 'paragraph',
    },
    {
      displayText: 'List',
      value: 'list',
    },
    {
      displayText: 'Step by Step',
      value: 'stepbystep',
    },
  ],
  Translate: [
    {
      displayText: 'English',
      value: 'english',
    },
    {
      displayText: 'Spanish',
      value: 'spanish',
    },
  ],
};

export const CONTENT_REPHRASE_DRAFT_MODES = {
  DRAFT: 'draft',
  FACTS: 'facts',
  MODE_DISPLAY: 'Generated Draft',
};

export const CONTENT_ASSIST_GENERATION_LIMIT = 100;
export const CONTENT_ASSIST_MAX_CHAR = 3000;

export const DEFAULT_URL_OPTION = 'New Tab';

export const FIXED_USER_GOALS = 'Fixed User Goals';
export const PREDICTIVE_USER_GOALS = 'Predictive User Goals';

export const PREDICTIVE_USER_GOAL_TYPES = [
  'True User Goal',
  'Instance User Goal',
  'Category User Goal',
];
