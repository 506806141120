import { Undo as CarbonUndo } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getUndoIcon = (size = 16) => {
  const Undo = (props) => <CarbonUndo size={size} {...props} />;

  Undo.propTypes = iconPropTypes;
  Undo.defaultProps = iconDefaultProps;

  return Undo;
};

export const Undo16 = getUndoIcon();
export const Undo20 = getUndoIcon(20);
export const Undo24 = getUndoIcon(24);
export const Undo32 = getUndoIcon(32);
