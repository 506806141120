import { DocumentExport as CarbonDocumentExport } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getDocumentExportIcon = (size = 16) => {
  const DocumentExportIcon = (props) => (
    <CarbonDocumentExport size={size} {...props} />
  );

  DocumentExportIcon.propTypes = iconPropTypes;
  DocumentExportIcon.defaultProps = iconDefaultProps;

  return DocumentExportIcon;
};

export const DocumentExport16 = getDocumentExportIcon();
export const DocumentExport20 = getDocumentExportIcon(20);
export const DocumentExport24 = getDocumentExportIcon(24);
export const DocumentExport32 = getDocumentExportIcon(32);
