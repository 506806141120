import { PhoneOutgoing as CarbonPhoneOutgoing } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getPhoneOutgoingIcon = (size = 16) => {
  const PhoneOutgoingIcon = (props) => (
    <CarbonPhoneOutgoing size={size} {...props} />
  );

  PhoneOutgoingIcon.propTypes = iconPropTypes;
  PhoneOutgoingIcon.defaultProps = iconDefaultProps;

  return PhoneOutgoingIcon;
};

export const PhoneOutgoing16 = getPhoneOutgoingIcon();
export const PhoneOutgoing20 = getPhoneOutgoingIcon(20);
export const PhoneOutgoing24 = getPhoneOutgoingIcon(24);
export const PhoneOutgoing32 = getPhoneOutgoingIcon(32);
