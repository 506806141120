import React, { useContext } from 'react';

import { ToastNotification } from '@cms/ui';
import { NotificationsRoot } from './Styles';
import NotificationContext from '../../context/notificationContext';

const NotificationBar = () => {
  const {
    notification,
    notificationMessage,
    notificationTitle,
    notificationKind,
    clearNotification,
  } = useContext(NotificationContext);

  return (
    notification && (
      <NotificationsRoot>
        <ToastNotification
          lowContrast
          kind={notificationKind}
          title={notificationTitle}
          aria-label="Dismiss"
          caption={Intl.DateTimeFormat(navigator.language, {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }).format(new Date())}
          subtitle={<span>{notificationMessage}</span>}
          timeout={0}
          onClose={() => clearNotification()}
        />
      </NotificationsRoot>
    )
  );
};
export { NotificationBar };
