import { Unlink as CarbonUnlink } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getUnlinkIcon = (size = 16) => {
  const UnlinkIcon = (props) => <CarbonUnlink size={size} {...props} />;

  UnlinkIcon.propTypes = iconPropTypes;
  UnlinkIcon.defaultProps = iconDefaultProps;

  return UnlinkIcon;
};

export const Unlink16 = getUnlinkIcon();
export const Unlink20 = getUnlinkIcon(20);
export const Unlink24 = getUnlinkIcon(24);
export const Unlink32 = getUnlinkIcon(32);
