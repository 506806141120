import { ChatBot as CarbonChatBot } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getChatBotIcon = (size = 16) => {
  const ChatBotIcon = (props) => <CarbonChatBot size={size} {...props} />;

  ChatBotIcon.propTypes = iconPropTypes;
  ChatBotIcon.defaultProps = iconDefaultProps;

  return ChatBotIcon;
};

export const ChatBot16 = getChatBotIcon();
export const ChatBot20 = getChatBotIcon(20);
export const ChatBot24 = getChatBotIcon(24);
export const ChatBot32 = getChatBotIcon(32);
