import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useContext } from 'react';
import { useUsersData } from '../graphql/queries/usersData';
import { getUserClientCodes } from '../helpers/user';

const CurrentUserContext = createContext();

export const useCurrentUser = () => useContext(CurrentUserContext);

export const CurrentUserProvider = ({ children }) => {
  const { user = {}, isLoading: auth0Loading } = useAuth0();
  const { data: { users = [] } = {}, loading: usersDataLoading } = useUsersData(
    user.email
  );
  const { id, customers = [] } = (users && users[0]) || {};
  const clientCodes = getUserClientCodes(user) || [];

  const filteredCustomers = customers.filter((customer) =>
    clientCodes.includes(customer?.code)
  );

  return (
    <CurrentUserContext.Provider
      value={{
        id,
        customers: filteredCustomers,
        isLoading: auth0Loading || usersDataLoading,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

CurrentUserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
