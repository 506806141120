import { Add as CarbonAdd } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getAddIcon = (size = 16) => {
  const AddIcon = (props) => <CarbonAdd size={size} {...props} />;

  AddIcon.propTypes = iconPropTypes;
  AddIcon.defaultProps = iconDefaultProps;

  return AddIcon;
};

export const Add16 = getAddIcon();
export const Add20 = getAddIcon(20);
export const Add24 = getAddIcon(24);
export const Add32 = getAddIcon(32);
