import json5 from 'json5';

// export for testing
export const getFeatureFlags = () => {
  try {
    const { FEATURE_FLAGS = '{}' } = window.config;
    return json5.parse(FEATURE_FLAGS) || {};
  } catch (error) {
    return {};
  }
};

export const getFeatureFlag = (key) => getFeatureFlags()[key];
