import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { Loading } from '@cms/ui';
import { useCurrentUser } from '../context/currentUserContext';

export const ErrorWrapper = styled.div`
  padding: 24px;
  padding-top: 72px;
`;

export const withAccessControl = (WrappedComponent) => {
  const ComponentWithAccessControl = (props) => {
    const navigate = useNavigate();
    const { client: clientCode } = useParams();
    const { customers, isLoading } = useCurrentUser();
    const codes = customers.map(({ code }) => code);

    if (isLoading) return <Loading small />;

    if (!codes.includes(clientCode)) {
      navigate('/not-found');
      return <></>;
    }

    return <WrappedComponent {...props} />;
  };

  return ComponentWithAccessControl;
};
