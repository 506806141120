import { FetchUpload as CarbonFetchUpload } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getFetchUploadIcon = (size = 16) => {
  const FetchUploadIcon = (props) => (
    <CarbonFetchUpload size={size} {...props} />
  );

  FetchUploadIcon.propTypes = iconPropTypes;
  FetchUploadIcon.defaultProps = iconDefaultProps;

  return FetchUploadIcon;
};

export const FetchUpload16 = getFetchUploadIcon();
export const FetchUpload20 = getFetchUploadIcon(20);
export const FetchUpload24 = getFetchUploadIcon(24);
export const FetchUpload32 = getFetchUploadIcon(32);
