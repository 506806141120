/* eslint-disable react/prop-types */
import { Button as CarbonButton } from '@carbon/react';
import React, { forwardRef } from 'react';

/**
 *
 * @param {import('@carbon/react/lib/components/Button/Button').ButtonProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonButton}
 */
const ButtonForwarded = (props, ref) => <CarbonButton ref={ref} {...props} />;

export const Button = forwardRef(ButtonForwarded);
