/* eslint-disable react/prop-types */
import { TableExpandedRow as CarbonTableExpandedRow } from '@carbon/react';
import { forwardRef } from 'react';

/**
 *
 * @param {import('@carbon/react/lib/components/DataTable/TableExpandedRow').TableExpandedRow} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonTableExpandedRow}
 */
const TableExpandedRowForwarded = ({ ...props }, ref) => (
  <CarbonTableExpandedRow ref={ref} {...props} />
);

export const TableExpandedRow = forwardRef(TableExpandedRowForwarded);
