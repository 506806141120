/* eslint-disable react/prop-types */
import { forwardRef } from 'react';
import { Dropdown as CarbonDropdown } from '@carbon/react';

/**
 *
 * @param {import('@carbon/react/lib/components/Dropdown/Dropdown').DropdownProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonDropdown}
 */
const DropdownForwarded = (props, ref) => (
  <CarbonDropdown ref={ref} {...props} />
);

export const Dropdown = forwardRef(DropdownForwarded);
