import { Close as CarbonClose } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getCloseIcon = (size = 16) => {
  const CloseIcon = (props) => <CarbonClose size={size} {...props} />;

  CloseIcon.propTypes = iconPropTypes;
  CloseIcon.defaultProps = iconDefaultProps;

  return CloseIcon;
};

export const Close16 = getCloseIcon();
export const Close20 = getCloseIcon(20);
export const Close24 = getCloseIcon(24);
export const Close32 = getCloseIcon(32);
