/* eslint-disable prefer-template */
import debounce from 'lodash.debounce';
import { createGoToResponseUrl } from './urlHelper';
import {
  BUTTON_TYPE_PERSISTENT_BUTTON,
  BUTTON_TYPE_QUICK_REPLY,
  BUTTON_META_TYPE_FIXED,
  BUTTON_META_TYPE_FLEX,
  BUTTON_META_TYPE_URL_LINK,
  BUTTON_META_TYPE_PHONE_NUMBER,
  BUTTON_META_TYPE_TRANSFER_PHONE_NUMBER,
  BUTTON_META_TYPE_CUSTOMER_SERVICE_TEAM,
  BUTTON_META_TYPE_BROADCAST_EVENT,
  BUTTON_FIELD_TYPE,
  BUTTON_FIELD_ID,
  BUTTON_FIELD_LABEL,
  BUTTON_FIELD_META_TYPE,
  BUTTON_FIELD_META_DESTINATION_LABEL,
  BUTTON_FIELD_FIXED_RESPONSE,
  BUTTON_FIELD_FLEX_UTTERANCE,
  BUTTON_FIELD_PHONE_NUMBER,
  BUTTON_FIELD_TRANSFER_PHONE_NUMBER,
  BUTTON_FIELD_URL,
  BUTTON_FIELD_URL_OPTION,
  BUTTON_FIELD_CUSTOMER_SERVICE_CODE,
  BUTTON_FIELD_BROADCAST_EVENT_NAME,
  BUTTON_FIELD_SOURCE_RESPONSES,
  AUTOCOMPLETE_DEBOUNCE_TIMEOUT,
  MINIMUM_AUTOCOMPLETE_SEARCH_STRING_LENGTH,
  CONTENT_SEARCH_FILTER_OPTIONS,
  URL_PARAM_EXCLUDE_LIST,
  BUTTON_FIELD_USE_AS_CALLBACK,
  BUTTON_FIELD_SOURCE_GALLERY_ITEMS,
  BUTTON_META_TYPE_FUNCTION_INVOCATION,
  BUTTON_META_TYPE_CUSTOM_PAYLOAD,
  BUTTON_FIELD_FUNCTION_INVOCATION,
  BUTTON_FIELD_CUSTOM_PAYLOAD,
} from '../constants';

export const extractInputEventValue = ({ target }) => target.value;

export const debouncedCall = debounce(
  (func, arg) => func(arg),
  AUTOCOMPLETE_DEBOUNCE_TIMEOUT
);

export const isSearchStringValidForSearch = (search) =>
  search && search.length >= MINIMUM_AUTOCOMPLETE_SEARCH_STRING_LENGTH;

export const searchStringPartition = (label, searchTerm) => {
  const index = label.toLowerCase().indexOf(searchTerm.toLowerCase());
  let part1 = label;
  let part2 = '';
  let part3 = '';
  if (index >= 0) {
    part1 = label.slice(0, index);
    part2 = label.slice(index, index + searchTerm.length);
    part3 = label.slice(index + searchTerm.length);
  }
  return [part1, part2, part3];
};

const extractResponse = (response) =>
  response && {
    responseId: response?.id,
    channelName: response?.channel?.name,
    userGoalName: response?.user_goal?.name,
    userGoalDescription: response?.user_goal?.description,
  };

/**
 * @param {object} item
 * @param {string} item.destination_utterance
 * @param {string} item.destination_phone_number
 * @param {string} item.destination_transfer_phone_number
 * @param {string} item.destination_url
 * @param {string} item.destination_skill
 * @param {string} item.destination_pb_broadcast_event
 * @param {object} item.destination_response
 * @param {object} item.destination_function_invocation_uri
 * @param {object} item.destination_custom_payload
 * @param {object} item.destination_response.user_goal
 * @param {string} item.destination_response.user_goal.name
 * @returns {[string, string]}
 */
const getMetaType = (item) => {
  if (item.destination_utterance) {
    return [BUTTON_META_TYPE_FLEX, item.destination_utterance];
  }
  if (item.destination_phone_number) {
    return [BUTTON_META_TYPE_PHONE_NUMBER, item.destination_phone_number];
  }
  if (item.destination_transfer_phone_number) {
    return [
      BUTTON_META_TYPE_TRANSFER_PHONE_NUMBER,
      item.destination_transfer_phone_number,
    ];
  }
  if (item.destination_url) {
    return [BUTTON_META_TYPE_URL_LINK, item.destination_url];
  }
  if (item.destination_skill) {
    return [BUTTON_META_TYPE_CUSTOMER_SERVICE_TEAM, item.destination_skill];
  }
  if (item.destination_pb_broadcast_event) {
    return [
      BUTTON_META_TYPE_BROADCAST_EVENT,
      item.destination_pb_broadcast_event,
    ];
  }
  if (item.destination_function_invocation_uri) {
    return [
      BUTTON_META_TYPE_FUNCTION_INVOCATION,
      item.destination_function_invocation_uri,
    ];
  }
  if (item.destination_custom_payload) {
    return [BUTTON_META_TYPE_CUSTOM_PAYLOAD, item.destination_custom_payload];
  }
  return [BUTTON_META_TYPE_FIXED, item?.destination_response?.user_goal?.name];
};

export const formatButton = (item) => {
  const [type, destinationLabel] = getMetaType(item);
  return {
    [BUTTON_FIELD_ID]: item?.id,
    [BUTTON_FIELD_LABEL]: item?.label,
    [BUTTON_FIELD_TYPE]: item?.type,
    [BUTTON_FIELD_META_TYPE]: type,
    [BUTTON_FIELD_META_DESTINATION_LABEL]: destinationLabel,
    [BUTTON_FIELD_FIXED_RESPONSE]: extractResponse(item?.destination_response),
    [BUTTON_FIELD_FLEX_UTTERANCE]: item?.destination_utterance,
    [BUTTON_FIELD_PHONE_NUMBER]: item?.destination_phone_number,
    [BUTTON_FIELD_TRANSFER_PHONE_NUMBER]:
      item?.destination_transfer_phone_number,
    [BUTTON_FIELD_URL]: item?.destination_url,
    [BUTTON_FIELD_URL_OPTION]: item?.url_options,
    [BUTTON_FIELD_CUSTOMER_SERVICE_CODE]: item?.destination_skill,
    [BUTTON_FIELD_BROADCAST_EVENT_NAME]: item?.destination_pb_broadcast_event,
    [BUTTON_FIELD_FUNCTION_INVOCATION]:
      item?.destination_function_invocation_uri,
    [BUTTON_FIELD_CUSTOM_PAYLOAD]: item?.destination_custom_payload,
    [BUTTON_FIELD_SOURCE_RESPONSES]: item?.sourceResponses || [],
    [BUTTON_FIELD_USE_AS_CALLBACK]: item?.use_as_callback || false,
    [BUTTON_FIELD_SOURCE_GALLERY_ITEMS]: item?.sourceGalleryItems,
  };
};

/**
 * Only abbreviates "Persistent Button" and "Quick Reply"
 *
 * @param {string} unformattedType
 * @returns {string}
 */
const abbreviateButtonType = (unformattedType) => {
  let type = unformattedType;
  switch (unformattedType.toLowerCase()) {
    case 'persistent button':
      type = 'PB';
      break;
    case 'quick reply':
      type = 'QR';
      break;
    default:
      break;
  }
  return type;
};

/**
 * @param {object} prop
 * @param {object} prop.keystoneButton
 * @param {string} prop.keystoneButton.id
 * @param {string} prop.keystoneButton.label
 * @param {string} prop.keystoneButton.type
 * @param {object[]} prop.keystoneButton.sourceResponses
 * @param {object[]} prop.keystoneButton.sourceGalleryItems
 * @param {string} prop.keystoneButton.destination_utterance
 * @param {string} prop.keystoneButton.destination_phone_number
 * @param {string} prop.keystoneButton.destination_transfer_phone_number
 * @param {string} prop.keystoneButton.destination_url
 * @param {string} prop.keystoneButton.destination_skill
 * @param {string} prop.keystoneButton.destination_pb_broadcast_event
 * @param {string} prop.keystoneButton.destination_function_invocation_uri
 * @param {string} prop.keystoneButton.destination_custom_payload
 * @param {object} prop.keystoneButton.destination_response
 * @param {string} prop.keystoneButton.destination_response.id
 * @param {object} prop.keystoneButton.destination_response.user_goal
 * @param {string} prop.keystoneButton.destination_response.user_goal.id
 * @param {string} prop.keystoneButton.destination_response.user_goal.name
 * @param {number} prop.index
 * @param {object} [prop.sortOptions]
 * @param {string} prop.sortOptions.locale
 * @param {Intl.CollatorOptions} prop.sortOptions.localeCompareOptions
 *
 * @returns {{
 *   id: string,
 *   label: string,
 *   type: string,
 *   buttonType: string,
 *   destination: string,
 *   placementInResponses: Response[],
 *   placementInGalleries: Gallery[],
 * }}
 */
export const formatKeystoneButtonToCELLButton = ({
  keystoneButton,
  sortOptions,
}) => {
  const locale = sortOptions?.locale ?? 'en-US';
  const localeCompareOptions = sortOptions?.localeCompareOptions ?? {
    sensitivity: 'base',
  };

  const { id, label, type: unformattedType } = keystoneButton;
  const type = abbreviateButtonType(unformattedType);

  const [buttonType, destination] = getMetaType(keystoneButton);

  const placementInResponses = [...(keystoneButton.sourceResponses ?? [])].sort(
    (sourceResponseA, sourceResponseB) =>
      sourceResponseA.user_goal.name.localeCompare(
        sourceResponseB.user_goal.name,
        locale,
        localeCompareOptions
      )
  );

  const placementInGalleries = [...(keystoneButton.sourceGalleryItems ?? [])]
    .sort((sourceGalleryItemA, sourceGalleryItemB) =>
      sourceGalleryItemA.title.localeCompare(
        sourceGalleryItemB.title,
        locale,
        localeCompareOptions
      )
    )
    // eslint-disable-next-line camelcase
    .map(({ image_url, button_order, ...rest }) => {
      let buttonOrder = [];
      try {
        // eslint-disable-next-line camelcase
        buttonOrder = JSON.parse(button_order || '{}')['Persistent Button'];
      } catch {
        // noop
      }

      return {
        imageUrl: image_url,
        buttonOrder,
        ...rest,
      };
    });
  const [destinationLabel] = getMetaType(keystoneButton);
  return {
    id,
    label,
    kind: type,
    type: buttonType,
    buttonType,
    destination,
    placementInResponses,
    placementInGalleries,
    [BUTTON_FIELD_META_DESTINATION_LABEL]: destinationLabel,
    [BUTTON_FIELD_FIXED_RESPONSE]: extractResponse(
      keystoneButton?.destination_response
    ),
    [BUTTON_FIELD_FLEX_UTTERANCE]: keystoneButton?.destination_utterance,
    [BUTTON_FIELD_PHONE_NUMBER]: keystoneButton?.destination_phone_number,
    [BUTTON_FIELD_TRANSFER_PHONE_NUMBER]:
      keystoneButton?.destination_transfer_phone_number,
    [BUTTON_FIELD_URL]: keystoneButton?.destination_url,
    [BUTTON_FIELD_URL_OPTION]: keystoneButton?.url_options,
    [BUTTON_FIELD_CUSTOMER_SERVICE_CODE]: keystoneButton?.destination_skill,
    [BUTTON_FIELD_FUNCTION_INVOCATION]:
      keystoneButton?.destination_function_invocation_uri,
    [BUTTON_FIELD_CUSTOM_PAYLOAD]: keystoneButton?.destination_custom_payload,
    [BUTTON_FIELD_BROADCAST_EVENT_NAME]:
      keystoneButton?.destination_pb_broadcast_event,
    [BUTTON_FIELD_SOURCE_RESPONSES]: keystoneButton?.sourceResponses ?? [],
    [BUTTON_FIELD_SOURCE_GALLERY_ITEMS]:
      keystoneButton?.sourceGalleryItems ?? [],
    [BUTTON_FIELD_USE_AS_CALLBACK]: keystoneButton?.use_as_callback ?? false,
  };
};

export const extractOrderAndSortButtons = ({
  buttons = [],
  buttonOrder,
  formatButtonFunction = formatButton,
}) => {
  const order = JSON.parse(buttonOrder || '{}');
  const extractButtonsInOrder = (buttonType) => {
    const buttonList = [];
    const idList = [];
    // get the ones that are in the order list first
    if (order?.[buttonType]?.length) {
      order[buttonType].forEach((index) => {
        const button = buttons.find(
          (b) => b.id === index && b.type === buttonType
        );
        if (button) {
          buttonList.push(formatButtonFunction(button));
          idList.push(button.id);
        }
      });
    }
    buttons
      .filter(({ id, type }) => !idList.includes(id) && type === buttonType)
      .forEach((button) => {
        buttonList.push(formatButtonFunction(button));
        idList.push(button.id);
      });
    return { buttonList, idList };
  };

  const { buttonList: QRList, idList: QROrder } = extractButtonsInOrder(
    BUTTON_TYPE_QUICK_REPLY
  );
  const { buttonList: PBList, idList: PROrder } = extractButtonsInOrder(
    BUTTON_TYPE_PERSISTENT_BUTTON
  );
  return {
    quickReplies: QRList,
    persistentButtons: PBList,
    buttonOrder: {
      [BUTTON_TYPE_QUICK_REPLY]: QROrder,
      [BUTTON_TYPE_PERSISTENT_BUTTON]: PROrder,
    },
  };
};

export const formatGalleryItem = ({
  gallery: {
    id,
    type,
    title,
    subtitle,
    image_url: imageUrl,
    buttons,
    button_order: buttonOrder,
    sourceResponses,
  },
  formatButtonFunction = formatButton,
}) => ({
  id,
  type: type?.name,
  title,
  subtitle,
  imageUrl,
  buttons: buttons?.length ? buttons.map(formatButtonFunction) : [],
  buttonOrder,
  sourceResponses,
});

export const extractOrderAndSortGalleryItems = ({
  galleryItems = [],
  galleryOrder,
  formatGalleryFunction = formatGalleryItem,
}) => {
  const order = JSON.parse(galleryOrder || '[]');
  const galleryList = [];
  const idList = [];
  // get the ones that are in the order list first
  if (order?.length) {
    order.forEach((idFromOrder) => {
      const gallery = galleryItems.find((g) => g.id === idFromOrder);
      if (gallery) {
        galleryList.push(formatGalleryFunction({ gallery }));
        idList.push(gallery.id);
      }
    });
  }
  galleryItems
    .filter(({ id }) => !idList.includes(id))
    .forEach((gallery) => {
      galleryList.push(formatGalleryFunction({ gallery }));
      idList.push(gallery.id);
    });
  return { galleryItems: galleryList, galleryOrder: idList };
};

export const formatGalleryItemsForCELL = ({
  galleryItems,
  sortOptions = {},
}) => {
  const locale = sortOptions?.locale || 'en-US';
  return galleryItems.map((galleryItem) => {
    const formattedGalleryItem = formatGalleryItem({ gallery: galleryItem });
    const { persistentButtons, buttonOrder } = extractOrderAndSortButtons({
      buttons: galleryItem?.buttons,
      buttonOrder: galleryItem?.button_order,
    });
    const placementInResponses = [...(galleryItem.sourceResponses ?? [])].sort(
      (sourceResponseA, sourceResponseB) =>
        sourceResponseA.user_goal.name.localeCompare(
          sourceResponseB.user_goal.name,
          locale,
          { sensitivity: 'base' }
        )
    );
    return {
      ...formattedGalleryItem,
      buttons: persistentButtons,
      buttonOrder: buttonOrder['Persistent Button'],
      placementInResponses,
    };
  });
};

export const formatResponse = (response) => ({
  id: response?.id || '',
  responseText: response?.text || '',
  channelCode: response?.channel?.code || '',
  clientCode: response?.customer?.code || '',
  userGoalName: response?.user_goal?.name || '',
  userGoalDescription: response?.user_goal?.description || '',
  customerStatus: response?.customer_status?.name || '',
  responseTags: response?.tags || [],
  assignee: response?.assignee?.name || '',
  contentLabel: response?.content_label || null,
  responseName: response?.user_goal?.response_name || null,
});

const findLabelWithId = (word) => {
  const { label = '' } =
    CONTENT_SEARCH_FILTER_OPTIONS.find((o) => o?.id === word && o?.label) ?? {};
  return label;
};

export const searchParamsFromURL = () => {
  const arr = [];
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  // eslint-disable-next-line no-restricted-syntax
  for (const [searchOption, searchText] of params) {
    const toContain = searchText[0] !== '!';
    if (URL_PARAM_EXCLUDE_LIST.every((e) => e !== searchOption)) {
      arr.push({
        searchOption: {
          id: searchOption,
          label: findLabelWithId(searchOption),
        },
        searchValue: toContain ? searchText : searchText.substring(1),
        toContain,
      });
    }
  }
  return arr;
};

export const urlFormattedSearchFilterOptions = (options) =>
  options
    .map((option) =>
      option?.id
        // Find all cap and add space at the start for the params
        .replace(/(?!^)([A-Z])/g, ' $1')
        // Replacing the above space with '-'
        // It is recommended to use the spinal-case which is highlighted by RFC3986
        // Converting to lower case and joining with '+'
        // It is recommended to use the reserved characters which is highlighted by RFC2396
        .replace(/[_\s]+(?=[a-zA-Z])/g, '-')
        .toLowerCase()
    )
    .join('+');

export const sanitizeString = (str) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const updateQueryStringParameterInUrl = ({ uri, key, value }) => {
  // Escape special characters in the key before using it in a regex
  const sanitizedKey = sanitizeString(key);

  // Regex for finding key and value from uri.
  const regex = new RegExp('([?&])' + sanitizedKey + '=.+?(&|$)', 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(regex)) {
    // if the key is already in the url it will update the value.
    return uri.replace(regex, '$1' + key + '=' + value + '$2');
  }
  // If key is not present in the url, it will append the new (key=value) param.
  return uri + separator + key + '=' + value;
};

export const RemoveParameterFromUrl = (url, parameter) =>
  url
    // Find the key and value.
    .replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1')
    // Removing the key and value from the url.
    .replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1');

export const groupUserGoalsByChatDrivers = (
  chatDrivers = [],
  userGoals = []
) => {
  // Ignore chatDrivers without category
  const updatedChatDrivers = chatDrivers.filter((driver) => driver.category);

  const result = updatedChatDrivers.reduce((acc, chat) => {
    acc[chat.category.name] = {
      [chat.name]: 0,
      ...acc[chat.category.name],
      count: 0,
    };

    return acc;
  }, {});

  if (userGoals.length <= 0) {
    return result;
  }

  const usergoals = userGoals.map((goal) => goal.chat_driver.name);
  const usergoalMap = usergoals.reduce((acc, curr) => {
    acc[curr] = (acc[curr] || 0) + 1;
    return acc;
  }, {});

  Object.keys(result).forEach((key) => {
    Object.keys(result[key]).forEach((goal) => {
      if (goal === 'count') {
        return;
      }

      result[key][goal] = usergoalMap[goal] || 0;
      result[key].count = result[key][goal] + result[key].count;
    });
  });

  return result;
};

export const groupUserGoalsByBankingObjects = (
  usergoals,
  entities,
  entityKinds
) => {
  const entityMap = entities.reduce((acc, entity) => {
    (acc[entity.kind_id] ||= []).push(entity.banking_object);
    return acc;
  }, {});

  const result = entityKinds.reduce((acc, kind) => {
    const bankingObjects = entityMap[kind.id];

    if (bankingObjects) {
      acc[kind.banking_object_category] = Object.fromEntries(
        [...bankingObjects, 'count'].map((bankingObject) => [bankingObject, 0])
      );
    }

    return acc;
  }, {});

  // if (usergoals.length <= 0) {
  //   return result;
  // }

  const usergoalMap = usergoals
    .map(({ entities: [{ banking_object: bankingObject }] }) => bankingObject)
    .reduce((acc, curr) => {
      acc[curr] = (acc[curr] || 0) + 1;
      return acc;
    }, {});

  Object.keys(result).forEach((key) => {
    Object.keys(result[key]).forEach((goal) => {
      if (goal === 'count') {
        return;
      }

      if (!usergoalMap[goal]) {
        delete result[key][goal];
        return;
      }

      result[key][goal] = usergoalMap[goal];
      result[key].count = result[key][goal] + result[key].count;
    });

    const boKeys = Object.values(result[key]);

    if (boKeys[boKeys.length - 1] === 0) {
      delete result[key];
    }
  });

  return result;
};

export const extractResponseIds = (error) => error?.match(/\d+/g) || [];

export const generateErrorMessageWithLinks = (error, responseIds, client) => {
  const responseLinks = responseIds.reduce((acc, responseId) => {
    const url = createGoToResponseUrl(
      window.location,
      client,
      client,
      responseId
    );
    return {
      ...acc,
      [responseId]: `<a href=${url}>${responseId}</a>`,
    };
  }, {});

  const message = error.replace(
    /(\d+)/g,
    (match) => responseLinks[match] || match
  );
  return message;
};

export const isTemplateError = (err) =>
  Boolean(
    err?.message && err.message.includes('{"type":"TemplateError","template":"')
  );
