import { ErrorWrapper } from './Style';

const NotFound = () => {
  return (
    <>
      <ErrorWrapper>
        <div>
          <h1>Something&apos;s gone wrong...</h1>
          <h3>Sorry, we can&apos;t find the page you are looking for.</h3>
          <p>
            Please verify the URL and try again or go to{' '}
            <a href="/cms">GVA Knowledge Bank</a> homepage to select the right
            page.
          </p>
        </div>
      </ErrorWrapper>
    </>
  );
};

export { NotFound };
