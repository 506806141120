import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@cms/ui';

const ConfirmModal = ({
  isOpen,
  titleText,
  bodyText,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) => (
  <Modal
    open={isOpen}
    passiveModal={false}
    modalHeading={titleText}
    primaryButtonText={confirmText}
    secondaryButtonText={cancelText}
    size="sm"
    preventCloseOnClickOutside
    onRequestClose={onCancel}
    onRequestSubmit={onConfirm}
    onSecondarySubmit={onCancel}
  >
    {bodyText}
  </Modal>
);

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  titleText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { ConfirmModal };
