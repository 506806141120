import { CheckmarkOutline as CarbonCheckmarkOutline } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getCheckmarkOutline = (size = 16) => {
  const CheckmarkOutline = (props) => (
    <CarbonCheckmarkOutline size={size} {...props} />
  );

  CheckmarkOutline.propTypes = iconPropTypes;
  CheckmarkOutline.defaultProps = iconDefaultProps;

  return CheckmarkOutline;
};

export const CheckmarkOutline16 = getCheckmarkOutline();
export const CheckmarkOutline20 = getCheckmarkOutline(20);
export const CheckmarkOutline24 = getCheckmarkOutline(24);
export const CheckmarkOutline32 = getCheckmarkOutline(32);
