/**
 * Original idea for this provider came from:
 * https://nextsteps.dev/apollo-client-graphQL-and-auth
 */

import React from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { onError } from '@apollo/client/link/error';
import { ClassPrefix } from '@carbon/react';
import { Notifications } from '../components/Notifications';
import { NOTIFICATION_TYPE } from '../constants';
import { isTemplateError } from '../helpers/utils';

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const errorLink = onError(({ graphQLErrors }) => {
    try {
      if (graphQLErrors) {
        const templateError = graphQLErrors.find(isTemplateError);

        if (templateError) {
          const invalidTemplate = JSON.parse(
            templateError.message.substring(
              templateError.message.indexOf('{'),
              templateError.message.lastIndexOf('}') + 1
            )
          );

          const apolloErrorsElement = document.getElementById('apolloErrors');
          render(
            <ClassPrefix prefix="bx">
              <Notifications
                notificationType={NOTIFICATION_TYPE.TEMPLATE_ERROR}
                invalidTemplate={invalidTemplate.template}
              />
            </ClassPrefix>,
            apolloErrorsElement
          );
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Could not display template error');
    }
  });

  const customFetch = async (_, options) => {
    const { operationName } = JSON.parse(options.body);
    const token = await getAccessTokenSilently();
    const modifiedOptions = {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    return fetch(
      `${window.config.GRAPHQL_URI}?operation=${operationName}`,
      modifiedOptions
    );
  };

  const httpLink = createHttpLink({
    fetch: customFetch,
  });

  const cache = new InMemoryCache({
    typePolicies: {
      responses: {
        fields: {
          buttons: {
            merge: false,
          },
          user_goal: {
            merge: false,
          },
          gallery_items: {
            merge: false,
          },
        },
      },
      gallery_items: {
        fields: {
          buttons: {
            merge: false,
          },
        },
      },
      users: {
        fields: {
          responses: {
            merge: false,
          },
        },
      },
    },
  });

  const apolloClient = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

AuthorizedApolloProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { AuthorizedApolloProvider };
