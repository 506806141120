import { RowCollapse as CarbonRowCollapse } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getRowCollapseIcon = (size = 16) => {
  const RowCollapseIcon = (props) => (
    <CarbonRowCollapse size={size} {...props} />
  );

  RowCollapseIcon.propTypes = iconPropTypes;
  RowCollapseIcon.defaultProps = iconDefaultProps;

  return RowCollapseIcon;
};

export const RowCollapse16 = getRowCollapseIcon();
export const RowCollapse20 = getRowCollapseIcon(20);
export const RowCollapse24 = getRowCollapseIcon(24);
export const RowCollapse32 = getRowCollapseIcon(32);
