/* eslint-disable react/prop-types */
import { TableRow as CarbonTableRow } from '@carbon/react';
import { forwardRef } from 'react';

/**
 *
 * @param {import('@carbon/react/lib/components/DataTable/TableRow').TableRowProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonTableRow}
 */
const TableRowForwarded = ({ ...props }, ref) => (
  <CarbonTableRow ref={ref} {...props} />
);

export const TableRow = forwardRef(TableRowForwarded);
