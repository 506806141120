import { RecentlyViewed as CarbonRecentlyViewed } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getRecentlyViewedIcon = (size = 16) => {
  const RecentlyViewedIcon = (props) => (
    <CarbonRecentlyViewed size={size} {...props} />
  );

  RecentlyViewedIcon.propTypes = iconPropTypes;
  RecentlyViewedIcon.defaultProps = iconDefaultProps;

  return RecentlyViewedIcon;
};

export const RecentlyViewed16 = getRecentlyViewedIcon();
export const RecentlyViewed20 = getRecentlyViewedIcon(20);
export const RecentlyViewed24 = getRecentlyViewedIcon(24);
export const RecentlyViewed32 = getRecentlyViewedIcon(32);
