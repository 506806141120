import React from 'react';
import { isValidElementType } from 'react-is';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loading } from '@cms/ui';

const ProtectedRoute = ({ component: Component, ...args }) => {
  const WrappedComponent = withAuthenticationRequired(Component, {
    onRedirecting: () => <Loading small />,
  });

  return <WrappedComponent {...args} />;
};

ProtectedRoute.propTypes = {
  component: (props, propName) => {
    if (props[propName] && !isValidElementType(props[propName])) {
      return new Error(
        "Invalid prop 'component' supplied to 'ProtectedRoute': the prop is not a valid React component"
      );
    }
    return undefined;
  },
};

ProtectedRoute.defaultProps = {
  component: () => {},
};

export { ProtectedRoute };
