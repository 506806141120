import { Help as CarbonHelp } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getHelpIcon = (size = 16) => {
  const HelpIcon = (props) => <CarbonHelp size={size} {...props} />;

  HelpIcon.propTypes = iconPropTypes;
  HelpIcon.defaultProps = iconDefaultProps;

  return HelpIcon;
};

export const Help16 = getHelpIcon();
export const Help20 = getHelpIcon(20);
export const Help24 = getHelpIcon(24);
export const Help32 = getHelpIcon(32);
