import { Tag as CarbonTag } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getTagIcon = (size = 16) => {
  const TagIcon = (props) => <CarbonTag size={size} {...props} />;

  TagIcon.propTypes = iconPropTypes;
  TagIcon.defaultProps = iconDefaultProps;

  return TagIcon;
};

export const Tag16 = getTagIcon();
export const Tag20 = getTagIcon(20);
export const Tag24 = getTagIcon(24);
export const Tag32 = getTagIcon(32);
