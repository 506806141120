import { Stethoscope as CarbonStethoscope } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getStethoscopeIcon = (size = 16) => {
  const StethoscopeIcon = (props) => (
    <CarbonStethoscope size={size} {...props} />
  );

  StethoscopeIcon.propTypes = iconPropTypes;
  StethoscopeIcon.defaultProps = iconDefaultProps;

  return StethoscopeIcon;
};

export const Stethoscope16 = getStethoscopeIcon();
export const Stethoscope20 = getStethoscopeIcon(20);
export const Stethoscope24 = getStethoscopeIcon(24);
export const Stethoscope32 = getStethoscopeIcon(32);
