import { Bullhorn as CarbonBullhorn } from '@carbon/react/icons';
import { iconPropTypes, iconDefaultProps } from '../../types/prop-types/icons';

const getBullhornIcon = (size = 16) => {
  const BullhornIcon = (props) => <CarbonBullhorn size={size} {...props} />;

  BullhornIcon.propTypes = iconPropTypes;
  BullhornIcon.defaultProps = iconDefaultProps;

  return BullhornIcon;
};

export const Bullhorn16 = getBullhornIcon();
export const Bullhorn20 = getBullhornIcon(20);
export const Bullhorn24 = getBullhornIcon(24);
export const Bullhorn32 = getBullhornIcon(32);
