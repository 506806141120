import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmModal } from './ConfirmModal';

const DEFAULT_CONFIG = {
  titleText: '',
  bodyText: '',
  confirmText: '',
  cancelText: '',
  callback: () => {},
};

const ConfirmModalContext = createContext();

export const ConfirmModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState(DEFAULT_CONFIG);

  const openModal = ({
    titleText,
    bodyText,
    confirmText,
    cancelText,
    callback,
  }) => {
    setConfig({
      titleText,
      bodyText,
      confirmText,
      cancelText,
      callback,
    });
    setIsOpen(true);
  };

  const resetModal = () => {
    setIsOpen(false);
    setConfig(DEFAULT_CONFIG);
  };

  const handleConfirm = () => {
    resetModal();
    config.callback(true);
  };

  const handleCancel = () => {
    resetModal();
    config.callback(false);
  };

  return (
    <ConfirmModalContext.Provider value={{ openModal }}>
      {children}
      <ConfirmModal
        isOpen={isOpen}
        titleText={config.titleText}
        bodyText={config.bodyText}
        confirmText={config.confirmText}
        cancelText={config.cancelText}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </ConfirmModalContext.Provider>
  );
};

ConfirmModalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const useConfirmModal = () => {
  const { openModal } = useContext(ConfirmModalContext);

  const getConfirmation = ({ titleText, bodyText, confirmText, cancelText }) =>
    new Promise((res) => {
      openModal({
        titleText,
        bodyText,
        confirmText,
        cancelText,
        callback: res,
      });
    });

  return { getConfirmation };
};
