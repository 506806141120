/* eslint-disable react/prop-types */
import { TableCell as CarbonTableCell } from '@carbon/react';
import { forwardRef } from 'react';

/**
 *
 * @param {import('@carbon/react/lib/components/DataTable/TableCell').TableCellProps} props
 * @param {import('react').ForwardedRef<any>} ref
 * @returns {CarbonTableCell}
 */
const TableCellForwarded = ({ ...props }, ref) => (
  <CarbonTableCell ref={ref} {...props} />
);

export const TableCell = forwardRef(TableCellForwarded);
